import { DEFAULT_WINDOW } from '../../constants'
import {
  ActionableItemsReducer,
  ActionableItemsActions,
  ActionableItemsTypes,
} from './types'

const status = { statusCode: 0, message: '' }

const initialState: ActionableItemsReducer = {
  isLoading: false,
  data: [],
  totalCount: 0,
  limit: 30,
  offset: 0,
  filter: {},
  days: DEFAULT_WINDOW,
  status,
  sortOptions: { column: 'time', direction: 'asc' },
  resendEmailResult: [],
  resendingEmails: false,
}

const actionableItems = (
  state = initialState,
  action: ActionableItemsActions,
): ActionableItemsReducer => {
  switch (action.type) {
    case ActionableItemsTypes.LOADING_ACTIONABLE_ITEMS:
      return {
        ...state,
        isLoading: action.payload,
      }
    case ActionableItemsTypes.SET_ACTIONABLE_ITEMS_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case ActionableItemsTypes.SET_ACTIONABLE_ITEMS_LIMIT:
      return {
        ...state,
        limit: action.payload,
      }
    case ActionableItemsTypes.SET_ACTIONABLE_ITEMS_OFFSET:
      return {
        ...state,
        offset: action.payload,
      }
    case ActionableItemsTypes.SET_ACTIONABLE_ITEMS_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      }
    case ActionableItemsTypes.SET_ACTIONABLE_ITEMS_FILTER:
      return {
        ...state,
        filter: action.payload,
      }
    case ActionableItemsTypes.SET_ACTIONABLE_ITEMS_STATUS:
      return {
        ...state,
        status: action.payload,
      }

    case ActionableItemsTypes.SET_ACTIONABLE_ITEMS_DAYS:
      return {
        ...state,
        days: action.payload,
      }
    case ActionableItemsTypes.SET_ACTIONABLE_ITEMS_SORT_OPTIONS:
      return {
        ...state,
        sortOptions: action.payload,
      }
    case ActionableItemsTypes.SET_EMAIL_RESEND_RESULT:
      return {
        ...state,
        resendEmailResult: action.payload,
      }
    case ActionableItemsTypes.SET_RESENDING_EMAILS:
      return {
        ...state,
        resendingEmails: action.payload,
      }
    case ActionableItemsTypes.RESET_REDUCER:
      if (action.payload) return initialState
      else return state
    default:
      return state
  }
}

export default actionableItems

import { IColumn, IRow } from "../../../components/RivataGrid";
import { convertDataEpochToDate, getClearLabel } from "../../../utils";

export const tagsReportColumns = (columns: Array<string>): Array<IColumn> =>
  columns.map((column) => {
    if (column === "last_gw_esn") {
      return { key: column, name: 'Last GW ESN', width: 150}
      }
    if(column === "mac"){
      return { key: column, name: "MAC", width: 150}
    }
    if(column === "last_asset_vin"){
      return { key: column, name: "Last Asset VIN", width: 150}
    }
    if(column === "report_run_time"){
      return { key: column, name: "Report Runtime"}
    }
    if(column === "customer_for_gw"){
      return { key: column, name: "Customer for GW"}
    }

      return { key: column, name: getClearLabel(column)}
    });

export const tagHistoryDetailsReportColumns = (columns: Array<string>): Array<IColumn> => {
  return columns.map((column) => {
    if (column === "mac") {
      return { key: column, name: "Tag MAC", width: 150 }
    }
    if (column === "location_name") {
      return { key: column, name: getClearLabel(column), width: 300 }
    }
    if (column === "last_reported_at_location") {
      return { key: column, name: getClearLabel(column), width: 200 }
    }

    return { key: column, name: getClearLabel(column) }
  })
}

export const tagsReportRows = (rows: Array<IRow>) =>
    rows.map((row) => ({
      ...row,
      customer_name: row.customer_name ? row.customer_name : "---",
      cargo_tag_name: row.cargo_tag_name ?? "---",
      report_run_time: row.report_run_time ? convertDataEpochToDate(row.report_run_time, null,null,true) : "---",
      last_report_timestamp: row.last_report_timestamp ? convertDataEpochToDate(row.last_report_timestamp,null,null,true) : "---",
      last_gw_esn: row.last_gw_esn ? row.last_gw_esn : '---',
      last_location_name: row.last_location_name ? row.last_location_name : "---",
      last_asset_name: row.last_asset_name ? row.last_asset_name : "---",
      last_asset_vin: row.last_asset_vin ? row.last_asset_vin : "---",
      time_since_last_report: row.time_since_last_report ? row.time_since_last_report : "---",
    }));

export const unassignedTagReportRows = (rows: Array<IRow>) =>
    rows.map((row) => ({
      ...row,
      customer_for_gw: row.customer_for_gw ? row.customer_for_gw : "---",
      first_report_date: row.first_report_date ? convertDataEpochToDate(row.first_report_date,null,null,true) : "---",
      last_report_date: row.last_report_date ? convertDataEpochToDate(row.last_report_date,null,null,true) : "---",
      last_gw_esn: row.last_gw_esn ? row.last_gw_esn : '---',
      last_location_name: row.last_location_name ? row.last_location_name : "---",
      last_asset_name: row.last_asset_name ? row.last_asset_name : "---",
      last_asset_vin: row.last_asset_vin ? row.last_asset_vin : "---",
      report_run_time: row.report_run_time ? convertDataEpochToDate(row.report_run_time, null,null,true) : "---",
    }));

export const lostTagReportRows = (rows: Array<IRow>) =>
    rows.map((row) => ({
      ...row,
      customer_name: row.customer_name ? row.customer_name  : "---",
      cargo_tag_name: row.cargo_tag_name ?? "---",
      last_report_time: row.last_report_timestamp ? convertDataEpochToDate(row.last_report_timestamp, null, null, true) : "---",
      report_run_time: row.report_run_time? convertDataEpochToDate(row.report_run_time, null,null,true) : "---",
      last_gw_esn: row.last_gw_esn ? row.last_gw_esn : '---',
      last_location_name: row.last_location_name ? row.last_location_name : "---",
      last_asset_name: row.last_asset_name ? row.last_asset_name : "---",
      last_asset_vin: row.last_asset_vin ? row.last_asset_vin : "---",
    }));

export const tagHistoryDetailsReportRows = (rows: Array<IRow>) => {
  return rows.map(row => {
    row.customer_name = row.customer_name ? row.customer_name  : "---"
    row.cargo_tag_name = row.cargo_tag_name ?? "---"
    row.confirmed_exit = row.confirmed_exit ? "True" : ""
    row.entry_time = convertDataEpochToDate(row.entry_time, null, null, true)
    row.last_reported_at_location = convertDataEpochToDate(row.last_reported_at_location, null, null, true)
    row.last_report_time = convertDataEpochToDate(row.last_report_time, null, null, true)

    return row
  })
}

import {
  LOADING_ASSETS,
  LOADING_TPMS_STATUS_TRACTOR_ASSETS,
  LOADING_TPMS_STATUS_TRAILER_ASSETS,
  LOADING_MAP_DATA,
  LOADING_STATUS,
  SET_ASSETS,
  SET_TPMS_STATUS_TRACTOR_ASSETS,
  SET_TPMS_STATUS_TRAILER_ASSETS,
  SET_ASSETS_SORT_OPTIONS,
  SET_TPMS_STATUS_TRACTOR_ASSETS_SORT_OPTIONS,
  SET_TPMS_STATUS_TRAILER_ASSETS_SORT_OPTIONS,
  SET_TPMS_STATUS_TRACTOR_TOTAL_COUNT,
  SET_TPMS_STATUS_TRAILER_TOTAL_COUNT,
  SET_TPMS_STATUS_TRACTOR_COLUMNS,
  SET_TPMS_STATUS_TRAILER_COLUMNS,
  SET_FILTER_MATCHES,
  SET_GPS,
  LOADING_GPS,
  LOADING_WARNINGS,
  LOADING_ACKNOWLEDGEMENTS,
  SET_ACKNOWLEDGEMENTS,
  SET_GPS_ERROR,
  SET_ASSETS_ERROR,
  SET_TPMS_STATUS_TRACTOR_ASSETS_ERROR,
  SET_TPMS_STATUS_TRAILER_ASSETS_ERROR,
  SET_WARNINGS_ERROR,
  SET_LOCATIONS,
  SET_LOCATIONS_STATUS,
  SET_ASSETS_COUNT,
  SET_FILTER,
  SET_TPMS_STATUS_TRACTOR_FILTER,
  SET_TPMS_STATUS_TRAILER_FILTER,
  SET_ASSETS_LIMIT,
  SET_TPMS_STATUS_TRACTOR_LIMIT,
  SET_TPMS_STATUS_TRAILER_LIMIT,
  SET_ASSETS_OFFSET,
  SET_TPMS_STATUS_TRACTOR_OFFSET,
  SET_TPMS_STATUS_TRAILER_OFFSET,
  REMOVE_ASSET_GROUP_FILTER,
  SET_ASSETS_CARDS_DATA_LOADING,
  SET_ASSETS_CARDS_DATA
} from "../actionTypes";

const initialState = {
  isLoadingMap: false,
  isLoadingStatus: false,
  isLoadingAcknowledgements: false,
  assets: {
    isLoading: false,
    data: [],
    totalCount: 0,
    limit: 30,
    offset: 0,
    error: {
      statusCode: 0,
      message: "",
    },
    sortOptions: {
      column: null,
      direction: null
    },
  },
  tpmsStatusTractorAssets: {
    isLoading: false,
    data: [],
    columns: [],
    totalCount: 0,
    limit: 30,
    offset: 0,
    error: {
      statusCode: 0,
      message: "",
    },
    sortOptions: {
      column: null,
      direction: null
    },
    filter: ""
  },
  tpmsStatusTrailerAssets: {
    isLoading: false,
    data: [],
    columns: [],
    totalCount: 0,
    limit: 30,
    offset: 0,
    error: {
      statusCode: 0,
      message: "",
    },
    sortOptions: {
      column: null,
      direction: null
    },
    filter: "",
  },
  gps: {},
  warnings: {},
  recentWarnings: {},
  acknowledgements: {},
  searchMatches: null, // asset ids that match search results
  locations: [],
  locationsStatus: { 
    statusCode: 0, 
    message: "" 
  },
  filter: "", // query string
  daysCount: 7,
  assetsCards: {
    isLoading: false,
    data: {}
  }
};

const copyInit = (item) => {
  item = item || {};
  return { ...item };
};

const filterAllWarnings = state => {
  const { acknowledgements } = state;
  const warnings = { ...state.warnings };
  const recentWarnings = { ...state.recentWarnings };
  Object.keys(acknowledgements).forEach(asset_id => {
    if (recentWarnings[asset_id] && recentWarnings[asset_id].data) {
      recentWarnings[asset_id].data = filterWarnings(recentWarnings[asset_id].data, acknowledgements[asset_id]);
      warnings[asset_id] = setWarning(warnings[asset_id], recentWarnings[asset_id].data);
    }
  });

  return { ...state, warnings };
}
const filterWarnings = (warnings, acknowledgements) => {
  const unacknowledgedWarnings = warnings.filter(warning => {
    return !acknowledgements.find(({ timestamp, position, type }) => {
      return timestamp === warning.timestamp && position === warning.position && type === warning.type;
    });
  });

  return unacknowledgedWarnings;
}

const setLoadingMap = (state, payload) => ({ ...state, isLoadingMap: payload });
const setLoadingStatus = (state, payload) => ({
  ...state,
  isLoadingStatus: payload,
});
const setLoadingAcknowledgements = (state, payload) => ({
  ...state,
  isLoadingAcknowledgements: payload,
});

const setAcknowledgements = (state, payload) => {
  if (!Array.isArray(payload)) {
    console.error("Error in setting acknowledgements:", payload);
    return state;
  }
  const acknowledgements = { ...state.acknowledgements };

  // Map out acknowledgements per asset.
  payload.forEach(ack => {
    const { asset_id } = ack;
    if (!(asset_id in acknowledgements)) {
      acknowledgements[asset_id] = [];
    }
    acknowledgements[asset_id].push(ack);
  })
  return { ...state, acknowledgements };
};

const setLoadingGps = (state, payload) => {
  const { id, isLoading } = payload;
  let gps = copyInit(state.gps[id]);
  gps.isLoading = isLoading;
  return { ...state, gps: { ...state.gps, [id]: gps } };
};
const setLoadingWarnings = (state, payload) => {
  const { id, isLoading } = payload;
  let warning = copyInit(state.warnings[id]);
  warning.isLoading = isLoading;
  return { ...state, warnings: { ...state.warnings, [id]: warning } };
};
const setSearchMatches = (state, payload) => ({
  ...state,
  searchMatches: payload,
});
// gps, and warnings need reset when assets reset
const setAssets = (state, key, payload) => ({
  ...state,
  [key]: {
    ...state[key],
    data: payload
  },
  gps: {},
  warnings: {},
});
const setGps = (state, payload) => {
  const { id, data } = payload;
  let gps = copyInit(state.gps[id]);
  gps.data = data;
  return { ...state, gps: { ...state.gps, [id]: gps } };
};
const setWarning = (warning, data) => {
  const statusData = data.length && data[0];
  warning.data = { status: statusData ? "warning" : "ok", data: statusData };
  return warning;
}

const setGpsError = (state, payload) => {
  const { id, error } = payload;
  let gps = copyInit(state.gps[id]);
  gps.error = error;
  return { ...state, gps: { ...state.gps, [id]: gps } };
};
const setWarningsError = (state, payload) => {
  const { id, error } = payload;
  let warning = copyInit(state.warnings[id]);

  warning.error = error;
  return { ...state, warnings: { ...state.warnings, [id]: warning } };
};

const setLocations = (state, payload) => {
  return { ...state, locations: payload };
}

const setLocationsStatus = (state, payload) => {
  return { ...state, locationsStatus: payload };
}

const setFilter = (state, payload) => {
  return { ...state, filter: payload }
}

const setFieldInObject = (state, key, fieldName, payload) => {
  return { ...state, [key]: { ...state[key], [fieldName]: payload } }
}

const removeAssetGroupFilter = (state, payload) => {
  const newFilter = decodeURIComponent(state.filter).replace(/filters=asset_group=-?\d*/gm, "")
  return { ...state, filter: newFilter }
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_ASSETS:
      return setFieldInObject(state, "assets", "isLoading", action.payload);
    case LOADING_TPMS_STATUS_TRACTOR_ASSETS:
      return setFieldInObject(state, "tpmsStatusTractorAssets", "isLoading", action.payload)
    case LOADING_TPMS_STATUS_TRAILER_ASSETS:
      return setFieldInObject(state, "tpmsStatusTrailerAssets", "isLoading", action.payload)
    case LOADING_MAP_DATA:
      return setLoadingMap(state, action.payload);
    case LOADING_STATUS:
      const warningsState = setLoadingStatus(state, action.payload);
      return filterAllWarnings(warningsState);
    case LOADING_GPS:
      return setLoadingGps(state, action.payload);
    case LOADING_WARNINGS:
      return setLoadingWarnings(state, action.payload);
    case SET_ASSETS:
      return setAssets(state, "assets", action.payload);
    case SET_TPMS_STATUS_TRACTOR_ASSETS:
      return setAssets(state, "tpmsStatusTractorAssets", action.payload)
    case SET_TPMS_STATUS_TRAILER_ASSETS:
      return setAssets(state, "tpmsStatusTrailerAssets", action.payload)
    case SET_ASSETS_SORT_OPTIONS:
      return setFieldInObject(state, "assets", "sortOptions", action.payload)
    case SET_TPMS_STATUS_TRACTOR_ASSETS_SORT_OPTIONS:
      return setFieldInObject(state, "tpmsStatusTractorAssets", "sortOptions", action.payload)
    case SET_TPMS_STATUS_TRAILER_ASSETS_SORT_OPTIONS:
      return setFieldInObject(state, "tpmsStatusTrailerAssets", "sortOptions", action.payload)
    case SET_TPMS_STATUS_TRACTOR_COLUMNS:
      return setFieldInObject(state, "tpmsStatusTractorAssets", "columns", action.payload)
    case SET_TPMS_STATUS_TRAILER_COLUMNS:
      return setFieldInObject(state, "tpmsStatusTrailerAssets", "columns", action.payload)
    case SET_FILTER_MATCHES:
      return setSearchMatches(state, action.payload);
    case SET_GPS:
      return setGps(state, action.payload);
    case SET_ASSETS_ERROR:
      return setFieldInObject(state, "assets", "error", action.payload);
    case SET_TPMS_STATUS_TRACTOR_ASSETS_ERROR:
      return setFieldInObject(state, "tpmsStatusTractorAssets", "error", action.payload)
    case SET_TPMS_STATUS_TRAILER_ASSETS_ERROR:
      return setFieldInObject(state, "tpmsStatusTrailerAssets", "error", action.payload)
    case SET_GPS_ERROR:
      return setGpsError(state, action.payload);
    case SET_WARNINGS_ERROR:
      return setWarningsError(state, action.payload);
    case LOADING_ACKNOWLEDGEMENTS:
      return setLoadingAcknowledgements(state, action.payload);
    case SET_ACKNOWLEDGEMENTS:
      const acknowledgementsState = setAcknowledgements(state, action.payload);
      return filterAllWarnings(acknowledgementsState);
    case SET_LOCATIONS:
      return setLocations(state, action.payload);
    case SET_LOCATIONS_STATUS:
      return setLocationsStatus(state, action.payload);
    case SET_ASSETS_COUNT:
      return setFieldInObject(state, "assets", "totalCount", action.payload)
    case SET_TPMS_STATUS_TRACTOR_TOTAL_COUNT:
      return setFieldInObject(state, "tpmsStatusTractorAssets", "totalCount", action.payload)
    case SET_TPMS_STATUS_TRAILER_TOTAL_COUNT:
      return setFieldInObject(state, "tpmsStatusTrailerAssets", "totalCount", action.payload)
    case SET_FILTER:
      return setFilter(state, action.payload);
    case SET_TPMS_STATUS_TRACTOR_FILTER:
      return setFieldInObject(state, "tpmsStatusTractorAssets", "filter", action.payload);
    case SET_TPMS_STATUS_TRAILER_FILTER:
      return setFieldInObject(state, "tpmsStatusTrailerAssets", "filter", action.payload);
    case SET_ASSETS_LIMIT:
      return setFieldInObject(state, "assets", "limit", action.payload);
    case SET_TPMS_STATUS_TRACTOR_LIMIT:
      return setFieldInObject(state, "tpmsStatusTractorAssets", "limit", action.payload);
    case SET_TPMS_STATUS_TRAILER_LIMIT:
      return setFieldInObject(state, "tpmsStatusTrailerAssets", "limit", action.payload);
    case SET_ASSETS_OFFSET:
      return setFieldInObject(state, "assets", "offset", action.payload);
    case SET_TPMS_STATUS_TRACTOR_OFFSET:
      return setFieldInObject(state, "tpmsStatusTractorAssets", "offset", action.payload);
    case SET_TPMS_STATUS_TRAILER_OFFSET:
      return setFieldInObject(state, "tpmsStatusTrailerAssets", "offset", action.payload);
    case REMOVE_ASSET_GROUP_FILTER:
      return removeAssetGroupFilter(state, action.payload);
    case SET_ASSETS_CARDS_DATA_LOADING:
      return setFieldInObject(state, "assetsCards", "isLoading", action.payload);
    case SET_ASSETS_CARDS_DATA:
      return setFieldInObject(state, "assetsCards", "data", action.payload);
    default:
      return state;
  }
};

export default dashboard;
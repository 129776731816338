import moment from 'moment'

import {
  CLEAR_SESSION,
  SET_SESSION,
  SET_TOKEN_LIFE_TIME,
  SET_REFRESHED_TOKEN,
  SAVING_SESSION,
  SET_SESSION_ERROR,
  CHECK_SESSION,
  SET_PREFERENCES,
  SET_AUTH_WHITELABEL,
  FAILED_TOKEN_REFRESH,
} from '../actionTypes'
import authService from '../../services/auth'
import api from '../../services/api'
import { store } from '../store'
import { fetchCustomers, fetchVersionInfo } from '../common/action'
import { fetchWhitelabel } from '../whitelabel/action'
import { removeExpiredLocalStorageItems } from '../../utils/localStorageUtils'
export const setTokenLifeTime = (payload) => ({
  type: SET_TOKEN_LIFE_TIME,
  payload,
})

export const setRefreshedToken = (payload) => ({
  type: SET_REFRESHED_TOKEN,
  payload,
})

export const setSavingSession = (payload) => {
  return {
    type: SAVING_SESSION,
    payload,
  }
}

export const setSessionError = (payload) => {
  return {
    type: SET_SESSION_ERROR,
    payload,
  }
}

export const clearSession = () => ({
  type: CLEAR_SESSION,
})

export const setPreferences = (payload) => ({
  type: SET_PREFERENCES,
  payload,
})

export const setAuthWhitelabel = (payload) => ({
  type: SET_AUTH_WHITELABEL,
  payload,
})

export function initSessionFromCallbackURI(callbackUri) {
  return function (dispatch) {
    return authService
      .parseCallbackURI(callbackUri) // parse the callback URL
      .then(() => authService.getUser()) // get a new session
      .then((session) => {
        dispatch({ type: SET_SESSION, payload: session })
        dispatch(setTokenLifeTime(session.expiresIn)) // set token life time
      })
  }
}

export function updatePreferences(newPreferences) {
  return async function (dispatch, getState) {
    try {
      const body = {
        timezone: newPreferences.timezone,
        phone_number: newPreferences.phone,
        old_phone_number: newPreferences.oldPhone,
        first_name: newPreferences.firstName,
        last_name: newPreferences.lastName,
        units_of_measurement_config: newPreferences.unitsOfMeasurementConfig
      }

      await api.putUserAttributes(body)

      const session = await authService.getUser()

      dispatch({ type: SET_SESSION, payload: session })

      return { status: 'ok' }
    } catch (err) {
      return { status: 'not ok' }
    }
  }
}

const getErrorData = (err) => {
  const errorStringArr = err.message.split(':')
  const message = errorStringArr[3] ? errorStringArr[3] : err.message
  const statusCode = parseInt(errorStringArr[1].split(' ')[2])

  return { statusCode, message }
}

export function requestVerificationCode() {
  return async function (dispatch, getState) {
    return api
      .requestVerificationCode()
      .then((res) => {
        return { status: res }
      })
      .catch((err) => {
        const { statusCode, message } = getErrorData(err)

        return { status: statusCode === 400 ? 'Invalid Phone Number' : message }
      })
      .finally(() => {})
  }
}

export function verifyCode(code) {
  return async function (dispatch, getState) {
    return api
      .verifyCode({ code: code })
      .then((res) => {
        if (res === 'approved') {
          dispatch(getUserAttributes())
        }

        return { status: res === 'pending' ? 'Invalid Verification Code' : res }
      })
      .catch((err) => {
        const { statusCode, message } = getErrorData(err)

        return {
          status:
            statusCode === 404
              ? 'Invalid Verification Code'
              : statusCode === 400
              ? 'Invalid Phone Number'
              : message,
        }
      })
      .finally(() => {})
  }
}

export function initSession() {
  return function (dispatch) {
    return authService
      .getUser()
      .then((session) => {
        dispatch({
          type: SET_SESSION,
          payload: { ...session, expiresIn: null },
        })
        dispatch(setTokenLifeTime(session.expiresIn)) // set token life time
      })
      .catch((err) => {
        dispatch({ type: CHECK_SESSION })

        store.dispatch({ type: FAILED_TOKEN_REFRESH, payload: err.message })
      })
  }
}

export const setSession = (session) => ({
  type: SET_SESSION,
  session,
})

export function getUserAttributes() {
  return (dispatch) => {
    return api
      .getUserAttributes()
      .then((res) => {
        dispatch(setPreferences(res))
      })
      .catch((err) => console.log(err))
  }
}
export function cleanUpLocalStorage() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      removeExpiredLocalStorageItems()
    })
  }
}
store.subscribe(() => {
  const lastAction = store.getState().lastAction
  let interval

  if (lastAction.type === SET_SESSION) {
    clearInterval(interval)
    store.dispatch(fetchCustomers())
    store.dispatch(getUserAttributes())
    if (window.location.pathname.split('/')[1] === 'whitelabel') {
      store.dispatch(fetchWhitelabel())
    }
    store.dispatch(fetchVersionInfo())
    store.dispatch(cleanUpLocalStorage())
  } else if (lastAction.type === SET_TOKEN_LIFE_TIME) {
    const timestamp = lastAction.payload
    interval = setInterval(() => {
      if (moment().unix() + 90 >= timestamp) {
        // refresh token before it is expired
        authService
          .refreshToken()
          .then((refreshedSession) => {
            store.dispatch(setRefreshedToken(refreshedSession.idToken.jwtToken))
            store.dispatch(
              setTokenLifeTime(refreshedSession.idToken.payload.exp),
            )
          })
          .catch((err) => {
            console.log(`Error during refreshing token: ${err.message}`)

            console.error(err)
            store.dispatch({ type: FAILED_TOKEN_REFRESH, payload: err.message })
          })
          .finally(() => {
            clearInterval(interval)
          })
      }
    }, 60000) // every 1 min check is token expired or not
  }
})

import React from 'react'
import { SearchIcon } from "../../componentsV2/Icon";
import { MenuItemType } from "../../componentsV2/Menu/MenuItem";
import { ListItemType } from '../../componentsV2/LabelSelector/List';
import { PressureWarningLevel, WarningCase } from '../../enums';


export const geofenceSearchItems: Array<ListItemType> = [
    { id: "inside", label: "All assets inside geofences", isSingleSelection: true, showFull: true, icon: <SearchIcon width={15} height={15} color="black" /> },
    { id: "outside", label: "All assets outside geofences", isSingleSelection: true, showFull: true, icon: <SearchIcon width={15} height={15} color="black" /> },
]

export const sensorStatusItems: Array<MenuItemType> = [
    { id: "ok", label: "Normal", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: PressureWarningLevel.lowPressure, label: "Low Pressure", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: PressureWarningLevel.criticalLowPressure, label: "Critical Low Pressure", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: PressureWarningLevel.overPressure, label: "Over Pressure", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: PressureWarningLevel.criticalOverPressure, label: "Critical Over Pressure", type: "checkbox", markType: "check", checked: false, parentId: null },
    { id: WarningCase.NO_STATUS_TPMS, label: "No Status", type: "checkbox", markType: "check", checked: false, parentId: null },
]

import { useMemo, useState, useEffect } from 'react'
import { ColumnsIds, UnitsOfMeasurement, NoDataLabels } from '../../enums'
import {
  getClearLabel,
  getIconsStatusData,
  getMileage,
  nameToCapitalizedLabel,
} from '../../utils'

export const useColumns = (
  locale,
  selected,
  customerIds,
  isSuperAdmin,
  subscriptions,
  isSuperStandardUser,
) => {
  return useMemo(() => {
    const cols = [
      {
        label: locale['Status'],
        id: ColumnsIds.STATUS,
        control: 'sort',
      },
      {
        label: locale['Customer'],
        id: ColumnsIds.CUSTOMER_NAME,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['Asset Name'],
        id: ColumnsIds.ASSET_NAME,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['Asset Type'],
        id: ColumnsIds.ASSET_TYPE,
        isDraggable: true,
      },
      {
        label: locale['AssetSubtype'],
        id: ColumnsIds.ASSET_SUBTYPE,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['VIN'],
        id: ColumnsIds.VIN,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['Asset Groups'],
        id: ColumnsIds.ASSET_GROUPS,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['Geofence'] ?? 'Geofence',
        id: ColumnsIds.GEOFENCES,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['Geofence Groups'] ?? 'Geofence Groups',
        id: ColumnsIds.GEOFENCE_GROUPS,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['Odometer'] ?? 'Odometer',
        id: ColumnsIds.ODOMETER,
        control: 'sort',
        isDraggable: true,
      },
      {
        label: locale['Last GPS Update'],
        id: ColumnsIds.LAST_GPS_UPDATE,
        control: 'sort',
        isDraggable: true,
      },
    ]

    if (customerIds?.length < 2 && !isSuperAdmin && !isSuperStandardUser) {
      const idx = cols.findIndex((el) => el.id === ColumnsIds.CUSTOMER_NAME)
      cols.splice(idx, 1)
    }

    // if do not have any subscription with status then remove status column
    if (
      !isSuperAdmin &&
      !subscriptions.TIRE_SENSOR &&
      !subscriptions.HUB_SENSOR &&
      !subscriptions.AXLE_LOAD &&
      !subscriptions.LINE_PRESSURE
    ) {
      const idx = cols.findIndex((el) => el.id === ColumnsIds.STATUS)

      if (idx > -1) cols.splice(idx, 1)
    }

    return cols
  }, [locale, customerIds, isSuperAdmin, subscriptions, isSuperStandardUser])
}

export const useTableRows = (
  assets,
  whiteLabelUrl,
  timezone,
  isSuperAdmin,
  customerIds,
  subscriptions,
  unitsOfMeasurement,
  isSuperStandardUser,
) => {
  const [tableRows, setTableRows] = useState([])
  useEffect(() => {
    const _tableBody = assets.map((asset) => {
      const { alt, src, styles, tooltips, counts } = getIconsStatusData(
        whiteLabelUrl,
        isSuperAdmin,
        asset,
      )

      const lastGpsUpdate = asset.formatted_datetime
      const link = `/details/${asset.vin}?reducePoints=true`

      let odometer_tooltip = null
      let odometer_label = '---'

      if (asset.asset_type === 'trailer') {
        if (asset?.odometer > 0) {
          odometer_label = `${getMileage(
            asset.odometer,
            unitsOfMeasurement,
          ).toFixed(0)} ${
            unitsOfMeasurement === UnitsOfMeasurement.imperial ? 'mi' : 'km'
          }`
        } else {
          odometer_tooltip = 'Please input latest odometer information'
        }
      } else if (asset.asset_type === 'tractor') {
        odometer_tooltip = 'GPS odometer not calculated for Tractors'
        odometer_label = null
      }

      const assetGroups = asset.asset_groups
        ? asset.asset_groups
            ?.map((ag) => {
              return ag.name
            })
            .join(', ')
        : NoDataLabels.DASH

      const row = {
        columns: [
          {
            type: 'icon',
            label: alt,
            src: src,
            classNames: styles,
            columnId: ColumnsIds.STATUS,
            assetId: asset.id,
            showIconTooltip: true,
            tooltipContents: tooltips,
            min_width: '145px',
            counts: counts,
          },
          {
            type: 'link',
            label: asset.customer_name,
            link,
            columnId: ColumnsIds.CUSTOMER_NAME,
            data: asset.customer_name,
            assetId: asset.id,
          },
          {
            type: 'link',
            label: asset.name || NoDataLabels.DASH,
            link,
            columnId: ColumnsIds.ASSET_NAME,
            data: asset.name,
            assetId: asset.id,
            max_width: '400px',
          },
          {
            type: 'link',
            label: nameToCapitalizedLabel(asset.asset_type),
            link,
            columnId: ColumnsIds.ASSET_TYPE,
            data: asset.asset_type,
            assetId: asset.id,
          },
          {
            type: 'link',
            label: getClearLabel(asset.asset_subtype),
            link,
            columnId: ColumnsIds.ASSET_SUBTYPE,
            data: asset.asset_subtype,
            assetId: asset.id,
          },
          {
            type: 'link',
            label: asset.vin,
            link,
            columnId: ColumnsIds.VIN,
            data: asset.vin,
            assetId: asset.id,
            max_width: '400px',
          },
          {
            type: 'link',
            label: assetGroups,
            link,
            columnId: ColumnsIds.ASSET_GROUPS,
            assetId: asset.id,
          },
          {
            type: 'link',
            label: asset.geofences?.replace(';', '; ') ?? NoDataLabels.DASH,
            link,
            columnId: ColumnsIds.GEOFENCES,
            data: asset.geofences,
            assetId: asset.id,
            max_width: '250px',
          },
          {
            type: 'link',
            label:
              asset.geofence_groups?.replace(';', ', ') ?? NoDataLabels.DASH,
            link,
            columnId: ColumnsIds.GEOFENCE_GROUPS,
            data: asset.geofence_groups,
            assetId: asset.id,
            max_width: '250px',
          },
          {
            type: 'link',
            label: odometer_label,
            link,
            columnId: ColumnsIds.ODOMETER,
            data: asset.odometer,
            assetId: asset.id,
            tooltip: odometer_tooltip,
          },
          {
            type: 'link',
            label: lastGpsUpdate,
            link,
            columnId: ColumnsIds.LAST_GPS_UPDATE,
            assetId: asset.id,
          },
        ],
        id: asset.id,
      }

      if (customerIds?.length < 2 && !isSuperAdmin && !isSuperStandardUser) {
        const idx = row.columns.findIndex(
          (el) => el.columnId === ColumnsIds.CUSTOMER_NAME,
        )
        row.columns.splice(idx, 1)
      }

      // if do not have any subscription with status then remove status column
      if (
        !isSuperAdmin &&
        !subscriptions.TIRE_SENSOR &&
        !subscriptions.HUB_SENSOR &&
        !subscriptions.AXLE_LOAD &&
        !subscriptions.LINE_PRESSURE
      ) {
        const idx = row.columns.findIndex(
          (el) => el.columnId === ColumnsIds.STATUS,
        )

        if (idx > -1) row.columns.splice(idx, 1)
      }

      return row
    })
    setTableRows(_tableBody)
  }, [
    assets,
    whiteLabelUrl,
    timezone,
    isSuperAdmin,
    customerIds,
    subscriptions,
    unitsOfMeasurement,
    isSuperStandardUser,
  ])
  return tableRows
}

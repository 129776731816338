// AUTH CONSTANTS
export const CLEAR_SESSION = 'CLEAR_SESSION'
export const SET_SESSION = 'SET_SESSION'
export const SET_REFRESHED_TOKEN = 'SET_REFRESHED_TOKEN'
export const SET_TOKEN_LIFE_TIME = 'SET_TOKEN_LIFE_TIME'
export const CHECK_SESSION = 'CHECK_SESSION'
export const SET_TIMEZONE = 'SET_TIMEZONE'
export const SAVING_SESSION = 'SAVING_SESSION'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_SESSION_ERROR = 'SET_SESSION_ERROR'
export const SET_PREFERENCES = 'SET_PREFERENCES'
export const SET_AUTH_WHITELABEL = 'SET_AUTH_WHITELABEL'
export const FAILED_TOKEN_REFRESH = 'FAILED_TOKEN_REFRESH'
export const SET_USER_DELETED = 'SET_USER_DELETED'
// ASSETS CONSTANTS
export const LOADING_ASSETS = 'LOADING_ASSETS'
export const SET_ASSETS = 'SET_ASSETS'
export const SET_ASSETS_LIMIT = 'SET_ASSETS_LIMIT'
export const SET_ASSETS_SORT_OPTIONS = 'SET_ASSETS_SORT_OPTIONS'
export const SET_ASSETS_OFFSET = 'SET_ASSETS_OFFSET'
export const SET_ASSETS_ERROR = 'SET_ASSETS_ERROR'
export const LOADING_TPMS_STATUS_TRACTOR_ASSETS =
  'LOADING_TPMS_STATUS_TRACTOR_ASSETS'
export const SET_TPMS_STATUS_TRACTOR_ASSETS = 'SET_TPMS_STATUS_TRACTOR_ASSETS'
export const SET_TPMS_STATUS_TRACTOR_TOTAL_COUNT =
  'SET_TPMS_STATUS_TRACTOR_TOTAL_COUNT'
export const SET_TPMS_STATUS_TRACTOR_COLUMNS = 'SET_TPMS_STATUS_TRACTOR_COLUMNS'
export const SET_TPMS_STATUS_TRACTOR_OFFSET = 'SET_TPMS_STATUS_TRACTOR_OFFSET'
export const SET_TPMS_STATUS_TRACTOR_LIMIT = 'SET_TPMS_STATUS_TRACTOR_LIMIT'
export const SET_TPMS_STATUS_TRACTOR_FILTER = 'SET_TPMS_STATUS_TRACTOR_FILTER'
export const SET_TPMS_STATUS_TRACTOR_ASSETS_SORT_OPTIONS =
  'SET_TPMS_STATUS_TRACTOR_ASSETS_SORT_OPTIONS'
export const SET_TPMS_STATUS_TRACTOR_ASSETS_ERROR =
  'SET_TPMS_STATUS_TRACTOR_ASSETS_ERROR'
export const LOADING_TPMS_STATUS_TRAILER_ASSETS =
  'LOADING_TPMS_STATUS_TRAILER_ASSETS'
export const SET_TPMS_STATUS_TRAILER_ASSETS = 'SET_TPMS_STATUS_TRAILER_ASSETS'
export const SET_TPMS_STATUS_TRAILER_ASSETS_SORT_OPTIONS =
  'SET_TPMS_STATUS_TRAILER_ASSETS_SORT_OPTIONS'
export const SET_TPMS_STATUS_TRAILER_TOTAL_COUNT =
  'SET_TPMS_STATUS_TRAILER_TOTAL_COUNT'
export const SET_TPMS_STATUS_TRAILER_COLUMNS = 'SET_TPMS_STATUS_TRAILER_COLUMNS'
export const SET_TPMS_STATUS_TRAILER_OFFSET = 'SET_TPMS_STATUS_TRAILER_OFFSET'
export const SET_TPMS_STATUS_TRAILER_LIMIT = 'SET_TPMS_STATUS_TRAILER_LIMIT'
export const SET_TPMS_STATUS_TRAILER_FILTER = 'SET_TPMS_STATUS_TRAILER_FILTER'
export const SET_TPMS_STATUS_TRAILER_ASSETS_ERROR =
  'SET_TPMS_STATUS_TRAILER_ASSETS_ERROR'
export const LOADING_GPS = 'LOADING_GPS'
export const SET_GPS = 'SET_GPS'
export const SET_GPS_ERROR = 'SET_GPS_ERROR'
export const SET_ACKNOWLEDGEMENTS = 'SET_ACKNOWLEDGEMENTS'
export const LOADING_ACKNOWLEDGEMENTS = 'LOADING_ACKNOWLEDGEMENTS'
export const SET_ACKNOWLEDGEMENTS_ERROR = 'SET_ACKNOWLEDGEMENTS_ERROR'
export const LOADING_WARNINGS = 'LOADING_WARNINGS'
export const SET_WARNINGS_ERROR = 'SET_WARNINGS_ERROR'
export const SET_FILTER_MATCHES = 'SET_FILTER_MATCHES'
export const SET_LOCATIONS = 'SET_LOCATIONS'
export const SET_LOCATIONS_STATUS = 'SET_LOCATIONS_STATUS'
export const SET_ASSETS_COUNT = 'SET_ASSETS_COUNT'
export const SET_FILTER = 'SET_FILTER'
export const SET_ASSETS_PAGE_LIMIT = 'SET_ASSETS_PAGE_LIMIT'
export const SET_LOGS_LOADING = 'SET_LOGS_LOADING'
export const SET_GATEWAY_LOGS = 'SET_GATEWAY_LOGS'
export const SET_LOGS_FETCH_ERROR = 'SET_LOGS_FETCH_ERROR'
export const SET_HIDE_AUTOGENERATED_FILTER = 'SET_HIDE_AUTOGENERATED_FILTER'
export const SET_LATEST_AXLE_LOADS = 'SET_LATEST_AXLE_LOADS'
export const REMOVE_ASSET_GROUP_FILTER = 'REMOVE_ASSET_GROUP_FILTER'
export const SET_ASSETS_CARDS_DATA = 'SET_ASSETS_CARDS_DATA'
export const SET_ASSETS_CARDS_DATA_LOADING = 'SET_ASSETS_CARDS_DATA_LOADING'

// This just lets us know that AL of the gps data is loaded, not per asset
export const LOADING_MAP_DATA = 'LOADING_MAP_DATA'
// This is for when ALL status warnings have loaded
export const LOADING_STATUS = 'LOADING_STATUS'

// DETAILS CONSTANTS
export const LOADING_ASSET_STATUS = 'LOADING_ASSET_STATUS'

export const SET_ASSET_TPMS_STATUS = 'SET_ASSET_TPMS_STATUS'
export const SET_SMARTHUB_STATUS = 'SET_SMARTHUB_STATUS'
export const SET_LINE_PRESSURE_STATUS = 'SET_LINE_PRESSURE_STATUS'

// NODES CONSTANTS
export const LOADING_NODE_INFO = 'LOADING_NODE_INFO'
export const SET_NODE_INFO = 'SET_NODE_INFO'
export const SET_NODE_INFO_ERROR = 'SET_NODE_INFO_ERROR'
export const POSTING_NODE_COMMAND = 'POSTING_NODE_COMMAND'
export const POST_NODE_COMMAND = 'POST_NODE_COMMAND'
export const POST_NODE_COMMAND_ERROR = 'POST_NODE_COMMAND_ERROR'
// WARNINGS CONSTANTS
export const LOADING_WARNING_LOCATION = 'LOADING_WARNING_LOCATION'
export const SET_WARNING_LOCATION = 'SET_WARNING_LOCATION'
export const SET_WARNING_LOCATION_ERROR = 'SET_WARNING_LOCATION_ERROR'
export const LOADING_TEMPERATURE = 'LOADING_TEMPERATURE'
export const SET_TEMPERATURE = 'SET_TEMPERATURE'
export const SET_TEMPERATURE_ERROR = 'SET_TEMPERATURE_ERROR'

export const RESET_WARNING_DETAILS_REDUCER = 'RESET_WARNING_DETAILS_REDUCER'

// WHITELABEL
export const LOADING_WHITELABEL = 'LOADING_WHITELABEL'
export const SET_WHITELABEL = 'SET_WHITELABEL'
export const SET_WHITELABEL_ERROR = 'SET_WHITELABEL_ERROR'
export const SET_WHITELABEL_LOADED = 'SET_WHITELABEL_LOADED'

// WHITELABEL ADMIN
export const LOADING_WHITELABEL_ADMIN = 'LOADING_WHITELABEL_ADMIN'
export const POSTING_WHITELABEL_ADMIN = 'POSTING_WHITELABEL_ADMIN'
export const SET_WHITELABEL_ADMIN = 'SET_WHITELABEL_ADMIN'
export const SET_WHITELABEL_ADMIN_ERROR = 'SET_WHITELABEL_ADMIN_ERROR'
export const POSTED_WHITELABEL_ADMIN = 'POSTED_WHITELABEL_ADMIN'
export const SET_WHITELABEL_ADMIN_DATA_DIRTY = 'SET_WHITELABEL_ADMIN_DATA_DIRTY'

export const SET_WHITELABEL_ADMIN_SMARTHUB_HEALTH_SVG =
  'SET_WHITELABEL_ADMIN_SMARTHUB_HEALTH_SVG'
export const SET_WHITELABEL_ADMIN_TPMS_HEALTH_SVG =
  'SET_WHITELABEL_ADMIN_TPMS_HEALTH_SVG'
export const SET_WHITELABEL_ADMIN_LINE_PRESSURE_HEALTH_SVG =
  'SET_WHITELABEL_ADMIN_LINE_PRESSURE_HEALTH_SVG'
export const SET_WHITELABEL_ADMIN_AXLE_LOAD_HEALTH_SVG =
  'SET_WHITELABEL_ADMIN_AXLE_LOAD_HEALTH_SVG'
export const SET_WHITELABEL_ADMIN_GATEWAY_HEALTH_SVG =
  'SET_WHITELABEL_ADMIN_GATEWAY_HEALTH_SVG'
export const SET_WHITELABEL_ADMIN_LOGO_ICON_PNG =
  'SET_WHITELABEL_ADMIN_LOGO_ICON_PNG'
export const SET_WHITELABEL_ADMIN_LOGO_FULL_PNG =
  'SET_WHITELABEL_ADMIN_LOGO_FULL_PNG'
export const SET_WHITELABEL_ADMIN_AUTH_IMAGE_PNG =
  'SET_WHITELABEL_ADMIN_AUTH_IMAGE_PNG'
export const SET_WHITELABEL_ADMIN_FLEET_SUMMARY_SMARTHUB_SVG =
  'SET_WHITELABEL_ADMIN_FLEET_SUMMARY_SMARTHUB_SVG'
export const SET_WHITELABEL_ADMIN_FLEET_SUMMARY_TPMS_SVG =
  'SET_WHITELABEL_ADMIN_FLEET_SUMMARY_TPMS_SVG'
export const SET_WHITELABEL_ADMIN_FLEET_SUMMARY_LINE_PRESSURE_SVG =
  'SET_WHITELABEL_ADMIN_FLEET_SUMMARY_LINE_PRESSURE_SVG'
export const SET_WHITELABEL_ADMIN_FLEET_SUMMARY_AXLE_LOAD_SVG =
  'SET_WHITELABEL_ADMIN_FLEET_SUMMARY_AXLE_LOAD_SVG'
export const SET_WHITELABEL_ADMIN_FLEET_SUMMARY_GEOFENCE_INSIDE_SVG =
  'SET_WHITELABEL_ADMIN_FLEET_SUMMARY_GEOFENCE_INSIDE_SVG'
export const SET_WHITELABEL_ADMIN_FLEET_SUMMARY_GEOFENCE_OUTSIDE_SVG =
  'SET_WHITELABEL_ADMIN_FLEET_SUMMARY_GEOFENCE_OUTSIDE_SVG'
export const SET_WHITELABEL_ADMIN_APP_SMARTHUB_ICON_PNG =
  'SET_WHITELABEL_ADMIN_APP_SMARTHUB_ICON_PNG'
export const SET_WHITELABEL_ADMIN_APP_AXLE_LOAD_ICON_PNG =
  'SET_WHITELABEL_ADMIN_APP_AXLE_LOAD_ICON_PNG'
export const SET_WHITELABEL_ADMIN_APP_LINE_PRESSURE_ICON_PNG =
  'SET_WHITELABEL_ADMIN_APP_LINE_PRESSURE_ICON_PNG'
export const SET_WHITELABEL_ADMIN_APP_TPMS_ICON_PNG =
  'SET_WHITELABEL_ADMIN_APP_TPMS_ICON_PNG'
export const SET_WHITELABEL_ADMIN_APP_LOGO_BRAND_LIGHT_PNG =
  'SET_WHITELABEL_ADMIN_APP_LOGO_BRAND_LIGHT_PNG'
export const SET_WHITELABEL_ADMIN_APP_LOGO_BRAND_DARK_PNG =
  'SET_WHITELABEL_ADMIN_APP_LOGO_BRAND_DARK_PNG'

// DOCUMENTS
export const LOADING_DOCUMENTS = 'LOADING_DOCUMENTS'
export const SET_DOCUMENTS = 'SET_DOCUMENTS'
export const SET_DOCUMENTS_ERROR = 'SET_DOCUMENTS_ERROR'

// PROVISION ADMIN
export const LOADING_PROVISION_ADMIN_ASSETS = 'LOADING_PROVISION_ADMIN_ASSETS'
export const SET_PROVISION_ADMIN_ASSETS_ERROR =
  'SET_PROVISION_ADMIN_ASSETS_ERROR'
export const SET_PROVISION_ADMIN_ASSETS = 'SET_PROVISION_ADMIN_ASSETS'
export const SET_PROVISION_ADMIN_ASSETS_COUNT =
  'SET_PROVISION_ADMIN_ASSETS_COUNT'
export const SET_PROVISION_ADMIN_ASSETS_SORT_OPTIONS =
  'SET_PROVISION_ADMIN_ASSETS_SORT_OPTIONS'

export const LOADING_PROVISION_ADMIN_USERS = 'LOADING_PROVISION_ADMIN_USERS'
export const SET_PROVISION_ADMIN_USERS_ERROR = 'SET_PROVISION_ADMIN_USERS_ERROR'
export const SET_PROVISION_ADMIN_USERS = 'SET_PROVISION_ADMIN_USERS'
export const SET_PROVISION_ADMIN_USERS_SORT_OPTIONS =
  'SET_PROVISION_ADMIN_USERS_SORT_OPTIONS'

export const LOADING_PROVISION_ADMIN_TPMS_ASSETS =
  'LOADING_PROVISION_ADMIN_TPMS_ASSETS'
export const SET_PROVISION_ADMIN_TPMS_ASSETS = 'SET_PROVISION_ADMIN_TPMS_ASSETS'
export const SET_PROVISION_ADMIN_TPMS_ASSETS_ERROR =
  'SET_PROVISION_ADMIN_TPMS_ASSETS_ERROR'
export const SET_PROVISION_ADMIN_TPMS_ASSETS_COUNT =
  'SET_PROVISION_ADMIN_TPMS_ASSETS_COUNT'
export const SET_PROVISION_ADMIN_TPMS_ASSETS_LIMIT =
  'SET_PROVISION_ADMIN_TPMS_ASSETS_LIMIT'
export const SET_PROVISION_ADMIN_TPMS_ASSETS_OFFSET =
  'SET_PROVISION_ADMIN_TPMS_ASSETS_OFFSET'
export const SET_PROVISION_ADMIN_TPMS_ASSETS_SORT_OPTIONS =
  'SET_PROVISION_ADMIN_TPMS_ASSETS_SORT_OPTIONS'
export const SET_PROVISION_ADMIN_TPMS_ASSETS_FILTER =
  'SET_PROVISION_ADMIN_TPMS_ASSETS_FILTER'

export const SET_TPMS_PROFILES_PROVISION = 'SET_TPMS_PROFILES_PROVISION'

// REPORTS
export const LOADING_REPORT = 'LOADING_REPORT'
export const SET_REPORT_COLUMNS = 'SET_REPORT_COLUMNS'
export const SET_REPORT_ROWS = 'SET_REPORT_ROWS'
export const SET_REPORT_ERROR = 'SET_REPORT_ERROR'
export const SET_SELECTED_REPORT_TYPE = 'SET_SELECTED_REPORT_TYPE'
export const CANCEL_REPORT = 'CANCEL_REPORT'

// SCHEDULED REPORTS
export const LOADING_SCHEDULED_REPORTS = 'LOADING_SCHEDULED_REPORTS'
export const SET_SCHEDULED_REPORTS = 'SET_SCHEDULED_REPORTS'
export const LOADING_SCHEDULED_REPORTS_USERS = 'LOADING_SCHEDULED_REPORTS_USERS'
export const SET_SCHEDULED_REPORTS_USERS = 'SET_SCHEDULED_REPORTS_USERS'

// TPMS PROFILES
export const LOADING_TPMS_PROFILES = 'LOADING_TPMS_PROFILES'
export const SET_TPMS_PROFILES = 'SET_TPMS_PROFILES'
export const SET_TPMS_SELECTED_PROFILE = 'SET_TPMS_SELECTED_PROFILE'

// COMMON
export const LOADING_CUSTOMERS = 'LOADING_CUSTOMERS'
export const SET_CUSTOMERS = 'SET_CUSTOMERS'
export const SET_SELECTED_CUSTOMERS_LIST = 'SET_SELECTED_CUSTOMERS_LIST'
export const SET_CUSTOMERS_ERROR = 'SET_CUSTOMERS_ERROR'

export const LOADING_DEFAULTS = 'LOADING_DEFAULTS'
export const SET_SYSTEM_DEFAULTS = 'SET_SYSTEM_DEFAULTS'
export const SET_CUSTOMER_DEFAULTS = 'SET_CUSTOMER_DEFAULTS'
export const SET_VERSION_INFO = 'SET_VERSION_INFO'

// TRESHOLDS
export const SET_TRESHOLDS_LOADING = 'SET_TRESHOLDS_LOADING'

// ASSET ADMIN INFO
export const LOADING_SUBSCRIPTION_INFO = 'LOADING_SUBSCRIPTION_INFO'
export const SET_SUBSCRIPTION_INFO = 'SET_SUBSCRIPTION_INFO'
export const SET_SUBSCRIPTION_INFO_ERROR = 'SET_SUBSCRIPTION_INFO_ERROR'

export const LOADING_SENSOR_INFO_JSON = 'LOADING_SENSOR_INFO_JSON'
export const SET_SENSOR_INFO_JSON = 'SET_SENSOR_INFO_JSON'
export const SET_SENSOR_INFO_JSON_ERROR = 'SET_SENSOR_INFO_JSON_ERROR'

export const LOADING_PROVISION_INFO = 'LOADING_PROVISION_INFO'
export const SET_PROVISION_INFO = 'SET_PROVISION_INFO'
export const SET_LAST_PROVISION_TIMESTAMP = 'SET_LAST_PROVISION_TIMESTAMP'
export const SET_PROVISION_INFO_ERROR = 'SET_PROVISION_INFO_ERROR'

export const SET_ASSET_INFO = 'SET_ASSET_INFO'

export const LOADING_HARDWARE_COMMANDS = 'LOADING_HARDWARE_COMMANDS'
export const SET_HARDWARE_COMMANDS = 'SET_HARDWARE_COMMANDS'
export const SET_HARDWARE_COMMANDS_ERROR = 'SET_HARDWARE_COMMANDS_ERROR'

export const SET_IS_LOADING_PROVISION_HISTORY =
  'SET_IS_LOADING_PROVISION_HISTORY'
export const SET_PROVISION_HISTORY = 'SET_PROVISION_HISTORY'
export const SET_PROVISION_HISTORY_ERROR = 'SET_PROVISION_HISTORY_ERROR'

export const SET_ASSET_ADMIN_GATEWAYS = 'SET_ASSET_ADMIN_GATEWAYS'

export const SET_IS_LOADING_GEOFENCES_EVENTS = 'SET_IS_LOADING_GEOFENCES_EVENTS'
export const SET_GEOFENCES_EVENTS = 'SET_GEOFENCES_EVENTS'
export const SET_GEOFENCES_EVENTS_ERROR = 'SET_GEOFENCES_EVENTS_ERROR'

export const GET_ADMIN_SENSOR_LOGS = 'GET_ADMIN_SENSOR_LOGS'
export const RESET_LOGS_TABLE = 'RESET_LOGS_TABLE '

// AXLE LOAD CALIBRATION

export const LOADING_AXLE_LOAD_ASSETS = 'LOADING_AXLE_LOAD_ASSETS'
export const SET_AXLE_LOAD_ASSETS = 'SET_AXLE_LOAD_ASSETS'
export const SET_AXLE_LOAD_ASSETS_ERROR = 'SET_AXLE_LOAD_ASSETS_ERROR'

export const SET_SELECTED_CALIBRATION_ASSET = 'SET_SELECTED_CALIBRATION_ASSET'

export const LOADING_AXLE_LOAD_CALIBRATION_DATA =
  'LOADING_AXLE_LOAD_CALIBRATION_DATA'
export const SET_AXLE_LOAD_CALIBRATION_DATA = 'SET_AXLE_LOAD_CALIBRATION_DATA'
export const SET_AXLE_LOAD_CALIBRATION_DATA_TOTAL_COUNT =
  'SET_AXLE_LOAD_CALIBRATION_DATA_TOTAL_COUNT'
export const SET_AXLE_LOAD_CALIBRATION_DATA_OFFSET =
  'SET_AXLE_LOAD_CALIBRATION_DATA_OFFSET'
export const SET_AXLE_LOAD_CALIBRATION_DATA_LIMIT =
  'SET_AXLE_LOAD_CALIBRATION_DATA_LIMIT'
export const SET_AXLE_LOAD_CALIBRATION_DATA_ERROR =
  'SET_AXLE_LOAD_CALIBRATION_DATA_ERROR'

// GEO LOCATIONS
export const SET_GEOLOCATIONS = 'SET_GEOLOCATIONS'
export const SET_GEOLOCATIONS_TOTAL_COUNT = 'SET_GEOLOCATIONS_TOTAL_COUNT'
export const SET_GEOLOCATIONS_OFFSET = 'SET_GEOLOCATIONS_OFFSET'
export const SET_GEOLOCATIONS_LIMIT = 'SET_GEOLOCATIONS_LIMIT'
export const SET_GEOLOCATIONS_TAGS_GEOFENCE_DATA =
  'SET_GEOLOCATIONS_TAGS_GEOFENCE_DATA'
export const SET_GEOLOCATIONS_LOADING = 'SET_GEOLOCATIONS_LOADING'
export const SET_GEOLOCATIONS_ORDER = 'SET_GEOLOCATIONS_ORDER'
export const SET_GEOLOCATIONS_FILTER = 'SET_GEOLOCATIONS_FILTER'
export const SET_GEOLOCATIONS_ASSETS_WITH_CARGOTAGS =
  'SET_GEOLOCATIONS_ASSETS_WITH_CARGOTAGS'
export const SET_SELECTED_LOCATION_OR_ASSET = 'SET_SELECTED_LOCATION_OR_ASSET'
export const SET_CARGO_TAG_UPLOAD_ERROR = 'SET_CARGO_TAG_UPLOAD_ERROR'
export const SET_GEOLOCATIONS_DROPDOWN = 'SET_GEOLOCATIONS_DROPDOWN'
export const SET_GEOLOCATIONS_DATA_STATUS = 'SET_GEOLOCATIONS_DATA_STATUS'
export const SET_GEOLOCATIONS_GEOFENCES_STATUS =
  'SET_GEOLOCATIONS_GEOFENCES_STATUS'
export const SET_GEOLOCATIONS_ASSETS_STATUS = 'SET_GEOLOCATIONS_ASSETS_STATUS'
export const SET_GEOLOCATIONS_SELECTED_STATUS =
  'SET_GEOLOCATIONS_SELECTED_STATUS'

// GEO LOCATION DETAILS

export const SET_GEOLOCATION_TAGS_DETAILS = 'SET_GEOLOCATION_TAGS_DETAILS'
export const SET_GEOLOCATION_DETAILS = 'SET_GEOLOCATION_DETAILS'
export const SET_IS_LOADING_DETAILS = 'SET_IS_LOADING_DETAILS'
export const SET_IS_LOADING_TAGS_DETAILS = 'SET_IS_LOADING_TAGS_DETAILS'

// GEO LOCATION TAG DETAILS

export const SET_IS_LOADING_TAG_GPS_DATA = 'SET_IS_LOADING_TAG_GPS_DATA'
export const SET_TAG_GPS_DATA = 'SET_TAG_GPS_DATA'
export const SET_IS_LOADING_TAG_GPS_DATA_DETAILED =
  'SET_IS_LOADING_TAG_GPS_DATA_DETAILED'
export const SET_TAG_GPS_DATA_DETAILED = 'SET_TAG_GPS_DATA_DETAILED'
export const SET_TAG_GPS_DATA_DETAILED_TOTAL_COUNT =
  'SET_TAG_GPS_DATA_DETAILED_TOTAL_COUNT'
export const SET_TAG_GPS_DATA_DETAILED_OFFSET =
  'SET_TAG_GPS_DATA_DETAILED_OFFSET'
export const SET_TAG_GPS_FILTERS = 'SET_TAG_GPS_FILTERS'
export const SET_IS_LOADING_FILTERS = 'SET_IS_LOADING_FILTERS'

// GEOFENCES
export const LOADING_GEOFENCES = 'LOADING_GEOFENCES'
export const SET_PAGED_GEOFENCES = 'SET_PAGED_GEOFENCES'
export const SET_CUSTOMER_GEOFENCES = 'SET_CUSTOMER_GEOFENCES'
export const SET_GEOFENCES_ERROR = 'SET_GEOFENCES_ERROR'
export const SET_GEOFENCES_TOTAL_COUNT = 'SET_GEOFENCES_TOTAL_COUNT'
export const SET_GEOFENCES_OFFSET = 'SET_GEOFENCES_OFFSET'

// REPORTS HISTORY
export const LOADING_REPORTS_HISTORY = 'LOADING_REPORTS_HISTORY'
export const SET_REPORTS_HISTORY = 'SET_REPORTS_HISTORY'

import React, { useState } from "react";

import moment from "moment-timezone";

import { Tooltip } from "reactstrap";

import "./style.scss";
import { NoDataLabels } from "../../../enums";

const borderColor = {
  criticalWarning: { background: "var(--criticalwarning)" },
  warning: { background: "var(--warning)" },
  normal: { background: "var(--success)" },
  noStatus: { background: "#6D6E71" },
  unknown: { background: "#F2F2F2" }
};

const getLayerPosition = (tirePosition, axleType, hidden) => {
  const driveAxle = axleType === "doubleDriveAxle";

  if (tirePosition.includes("STEER_LEFT")) {
    return { top: 0 };
  }

  if (tirePosition.includes("STEER_RIGHT")) {
    return { bottom: 0 };
  }

  if (tirePosition.includes("LEFT") && tirePosition.includes("OUTER")) {
    return { top: 0 };
  }

  if (tirePosition.includes("LEFT") && tirePosition.includes("INNER")) {
    return { top: driveAxle ? "42px" : "44px" };
  }

  if (tirePosition.includes("RIGHT") && tirePosition.includes("OUTER")) {
    return { bottom: 0 };
  }

  if (tirePosition.includes("RIGHT") && tirePosition.includes("INNER")) {
    return { bottom: driveAxle ? "42px" : "44px" };
  }
  if ((hidden && tirePosition.includes("RIGHT")) || (hidden && tirePosition.includes("LEFT"))) {
    return { display: 'none' };
  }
};

const TireLayer = ({
  tirePosition,
  tirePositionLabel,
  tireStatus,
  tirePressure,
  tireTemperature,
  pressureUnit,
  description,
  coldInflationPressure,
  latestTirePressureMeasurement,
  latestTireTemperatureMeasurement,
  axleType,
  timezone,
  hidden,
  isNoStatusSensor,
  fastLeak,
  isSuperAdmin
}) => {

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const layerPosition = getLayerPosition(tirePosition, axleType, hidden);

  const getTextWithUnits = (value) => {
    if (isNoStatusSensor) {
      return value;
    }
    return `${value} ${pressureUnit}`
  }

  return (
    <>
      {!hidden && <div
        id={tirePosition}
        className={`tire-layer ${tireStatus} ${axleType}`}
        style={{ ...layerPosition, boxShadow: `0 0 2px ${borderColor[tireStatus].background}` }}
      >
        <span>{tirePressure}</span>
        {<Tooltip
          innerClassName={`tire-tooltip ${tireStatus}`}
          placement="top"
          toggle={toggle}
          isOpen={tooltipOpen}
          target={tirePosition}
          autohide={false}
          hideArrow={true}
        >
          Sensor Type: TPMS <br />
          {tireStatus !== 'unknown' ? description : "No Data"} <br />
          {tirePositionLabel} <br />
          Pressure: {tireStatus !== 'unknown' ? getTextWithUnits(tirePressure) : NoDataLabels.DASH} <br />
          CIP: {tireStatus !== 'unknown' ? getTextWithUnits(coldInflationPressure) : NoDataLabels.DASH}  <br />
          {tireStatus !== 'unknown' && `Latest Pressure Measurement:  ${latestTirePressureMeasurement} ${(moment().tz(timezone).format("z"))}`}
          {tireStatus === 'unknown' && `Latest Pressure Measurement:    -`} <br />
          {latestTireTemperatureMeasurement && <>
            Temperature: {tireTemperature} <br />
            Latest Temperature Measurement: {latestTireTemperatureMeasurement} {(moment().tz(timezone).format("z"))} <br />
          </>
          }
          {(fastLeak && isSuperAdmin) && <><br/><b>Warning: Pressure Fast Leak detected!</b></>}
        </Tooltip>}
      </div >}
    </>
  );
};

export default TireLayer;

import { WarningCase, WarningCaseToLabelMapping } from '../../enums'
import { MenuItemType } from '../Menu/MenuItem'

export const sensorStatusItems: Array<MenuItemType> = [
  {
    id: 'tire',
    label: 'Tire',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    subMenuExpanded: true,
  },
  {
    id: WarningCase.CRITICAL_OVER_PRESSURE,
    label: WarningCaseToLabelMapping[WarningCase.CRITICAL_OVER_PRESSURE],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'tire',
  },
  {
    id: WarningCase.OVER_PRESSURE,
    label: WarningCaseToLabelMapping[WarningCase.OVER_PRESSURE],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'tire',
  },
  {
    id: WarningCase.CRITICAL_LOW_PRESSURE,
    label: WarningCaseToLabelMapping[WarningCase.CRITICAL_LOW_PRESSURE],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'tire',
  },
  {
    id: WarningCase.LOW_PRESSURE,
    label: WarningCaseToLabelMapping[WarningCase.LOW_PRESSURE],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'tire',
  },
  {
    id: WarningCase.ABNORMAL_HIGH_TIRE_VIBRATION,
    label: WarningCaseToLabelMapping[WarningCase.ABNORMAL_HIGH_TIRE_VIBRATION],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'tire',
  },
  {
    id: WarningCase.ABNORMAL_TIRE_VIBRATION,
    label: WarningCaseToLabelMapping[WarningCase.ABNORMAL_TIRE_VIBRATION],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'tire',
  },
  // {
  //   id: WarningCase.FAST_LEAK,
  //   label: WarningCaseToLabelMapping[WarningCase.FAST_LEAK],
  //   type: 'checkbox',
  //   markType: 'check',
  //   checked: false,
  //   parentId: 'tire',
  // },
  {
    id: 'hub',
    label: 'Hub',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    subMenuExpanded: true,
  },
  {
    id: WarningCase.CRITICAL_HIGH_TEMPERATURE,
    label: WarningCaseToLabelMapping[WarningCase.CRITICAL_HIGH_TEMPERATURE],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'hub',
  },
  {
    id: WarningCase.HIGH_TEMPERATURE,
    label: WarningCaseToLabelMapping[WarningCase.HIGH_TEMPERATURE],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'hub',
  },
  {
    id: WarningCase.ABNORMAL_HIGH_BEARING_VIBRATION,
    label:
      WarningCaseToLabelMapping[WarningCase.ABNORMAL_HIGH_BEARING_VIBRATION],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'hub',
  },
  {
    id: WarningCase.ABNORMAL_BEARING_VIBRATION,
    label: WarningCaseToLabelMapping[WarningCase.ABNORMAL_BEARING_VIBRATION],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'hub',
  },
  {
    id: 'line_pressure',
    label: 'Line Pressure',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    subMenuExpanded: true,
  },
  {
    id: WarningCase.LOW_LINE_PRESSURE,
    label: WarningCaseToLabelMapping[WarningCase.LOW_LINE_PRESSURE],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'line_pressure',
  },
  {
    id: 'no_status',
    label: 'No status',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    subMenuExpanded: true,
  },
  {
    id: WarningCase.NO_STATUS_TPMS,
    label: WarningCaseToLabelMapping[WarningCase.NO_STATUS_TPMS],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'no_status',
  },
  {
    id: WarningCase.NO_STATUS_SMARTHUB,
    label: WarningCaseToLabelMapping[WarningCase.NO_STATUS_SMARTHUB],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'no_status',
  },
  {
    id: WarningCase.NO_STATUS_LINE_PRESSURE,
    label: WarningCaseToLabelMapping[WarningCase.NO_STATUS_LINE_PRESSURE],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'no_status',
  },
  {
    id: WarningCase.NO_STATUS_AXLE_LOAD,
    label: WarningCaseToLabelMapping[WarningCase.NO_STATUS_AXLE_LOAD],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'no_status',
  },
  {
    id: 'asset_state',
    label: 'Asset State',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    subMenuExpanded: true,
  },
  {
    id: WarningCase.MOVEMENT_WITHOUT_ABS,
    label: WarningCaseToLabelMapping[WarningCase.MOVEMENT_WITHOUT_ABS],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'asset_state',
  },
  {
    id: 'asset_speed',
    label: 'Asset Speed',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
    subMenuExpanded: true,
  },
  {
    id: WarningCase.OVER_SPEED,
    label: WarningCaseToLabelMapping[WarningCase.OVER_SPEED],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'asset_speed',
  },
  {
    id: WarningCase.CRITICAL_OVER_SPEED,
    label: WarningCaseToLabelMapping[WarningCase.CRITICAL_OVER_SPEED],
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: 'asset_speed',
  },
  {
    id: 'ok',
    label: 'Normal',
    type: 'checkbox',
    markType: 'check',
    checked: false,
    parentId: null,
  },
]

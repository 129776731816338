import React, { useEffect, useState } from "react"
import { Field } from "formik"
import { Row, Col, Label, Tooltip } from "reactstrap"

import RivataFormField from "../../../../components/RivataFormField"

import { NotificationTypes, WarningType } from "../../../../enums"

import { getClearLabel, getCurrentEpoch } from "../../../../utils"
import { cloneDeep } from "lodash"

interface WarningItem {
    id: string;
    [NotificationTypes.none]: boolean;
    [NotificationTypes.all]: boolean;
    [NotificationTypes.criticalWarning]?: boolean;
    [NotificationTypes.warning]?: boolean;
    [NotificationTypes.geofenceEntry]?: boolean;
    [NotificationTypes.geofenceExit]?: boolean;
}

interface RadioButtonConfig {
    warning_types: {
        name: string,
        items: WarningItem[]
    },
    non_warning_types: {
        name: string,
        items: WarningItem[]
    }
}

const radioButtonConfig: RadioButtonConfig = {
    warning_types: {
        "name": "Warning Types and Levels",
        "items": [{
            id: WarningType.tirePressure,
            [NotificationTypes.none]: false,
            [NotificationTypes.all]: false,
            [NotificationTypes.criticalWarning]: false,
            [NotificationTypes.warning]: false
        },
        {
            id: WarningType.linePressure,
            [NotificationTypes.none]: false,
            [NotificationTypes.all]: false
        },
        {
            id: WarningType.hubTemperature,
            [NotificationTypes.none]: false,
            [NotificationTypes.all]: false,
            [NotificationTypes.criticalWarning]: false,
            [NotificationTypes.warning]: false
        },
        {
            id: WarningType.assetState,
            [NotificationTypes.none]: false,
            [NotificationTypes.all]: false
        },
        {
            id: WarningType.tireVibration,
            [NotificationTypes.none]: false,
            [NotificationTypes.all]: false,
            [NotificationTypes.criticalWarning]: false,
            [NotificationTypes.warning]: false
        },
        {
            id: WarningType.bearingVibration,
            [NotificationTypes.none]: false,
            [NotificationTypes.all]: false,
            [NotificationTypes.criticalWarning]: false,
            [NotificationTypes.warning]: false
        },
        {
            id: WarningType.assetSpeed,
            [NotificationTypes.none]: false,
            [NotificationTypes.all]: false,
            [NotificationTypes.criticalWarning]: false,
            [NotificationTypes.warning]: false
        },
        {
            id: WarningType.pressureFastLeak,
            [NotificationTypes.none]: false,
            [NotificationTypes.all]: false
        }],
    },
    non_warning_types: {
        "name": "Non Warning Alerts",
        "items": [{
            id: "geofence",
            [NotificationTypes.none]: false,
            [NotificationTypes.all]: false,
            [NotificationTypes.geofenceEntry]: false,
            [NotificationTypes.geofenceExit]: false
        }]
    }
}

interface Props {
    disabled: boolean
    subscriptions: any
    isSuperAdmin: boolean
}

const InputsGroup: React.FC<Props> = ({ disabled, subscriptions, isSuperAdmin }) => {
    const [tooltipOpened, setTooltipOpened] = useState(false)
    const [combinedTooltipOpened, setCombinedToolitpOpened] = useState(false)
    const [filteredRadioInputsConfig, setFilteredRadioInputsConfig] = useState<RadioButtonConfig>(radioButtonConfig)

    useEffect(() => {
        if (subscriptions) {
            if (isSuperAdmin) {
                setFilteredRadioInputsConfig(radioButtonConfig)
            } else {
                let defaults = cloneDeep(radioButtonConfig)
                const epoch = getCurrentEpoch()

                if (!subscriptions.TIRE_SENSOR || subscriptions?.TIRE_SENSOR?.valid_to < epoch) {
                    defaults.warning_types.items = defaults.warning_types.items.filter(c => ![WarningType.tirePressure, WarningType.pressureFastLeak].includes(c.id))
                }
                if (!subscriptions.LINE_PRESSURE || subscriptions?.LINE_PRESSURE?.valid_to < epoch) {
                    defaults.warning_types.items = defaults.warning_types.items.filter(c => c.id !== WarningType.linePressure)
                }
                if (!subscriptions.HUB_SENSOR || subscriptions?.HUB_SENSOR?.valid_to < epoch) {
                    defaults.warning_types.items = defaults.warning_types.items.filter(c => ![WarningType.hubTemperature, WarningType.tireVibration, WarningType.bearingVibration].includes(c.id))
                }
                if (!subscriptions.GATEWAY || subscriptions?.GATEWAY?.valid_to < epoch) {
                    defaults.warning_types.items = defaults.warning_types.items.filter(c => ![WarningType.assetState, WarningType.assetSpeed].includes(c.id))
                    defaults.non_warning_types.items = defaults.non_warning_types.items.filter(c => c.id !== "geofence")
                }

                // TODO - remove after fast leak will be tested
                defaults.warning_types.items = defaults.warning_types.items.filter(c => c.id !== WarningType.pressureFastLeak)
                
                setFilteredRadioInputsConfig(defaults)
            }
        }

    }, [subscriptions, isSuperAdmin, setFilteredRadioInputsConfig])

    return (
        <Row>
            <RivataFormField
                md={6}
                label={"Profile Name"}
                name={"name"}
                minHeight={80}
                disabled={disabled}
            />

            <Col md="12" className="mt-3">
                <h4>Notification Types</h4>

                <div className="profile-form__checkbox">
                    <Field id="sms" type="checkbox" name="sms" disabled={disabled} />
                    <Label for="sms">SMS</Label>
                </div>

                <div className="profile-form__checkbox">
                    <Field id="email" type="checkbox" name="email" disabled={disabled} />
                    <Label for="email">Email</Label>
                </div>
            </Col>
            {Object.keys(filteredRadioInputsConfig).map((v) => {
                const ob = filteredRadioInputsConfig[v as keyof typeof filteredRadioInputsConfig]

                const items = ob.items;

                if (!items.length) {
                    return <></>
                }

                return (
                    <Col md="12" className="mt-4">
                        <h4>{ob.name}</h4>

                        {items.map((el) => {
                            const keys = Object.keys(el)
                            keys.splice(0, 1) // remove id key
                            return (
                                <div key={el.id} className="notification-types radio__input mt-2">
                                    <Label className="notification-types__title">{getClearLabel(el.id)}</Label>

                                    {keys.map(key => {
                                        return (
                                            <div key={el.id + key} className="d-flex align-items-center">
                                                <Field
                                                    id={el.id + key}
                                                    type="radio"
                                                    name={el.id}
                                                    value={key}
                                                    disabled={disabled}
                                                />
                                                <Label for={el.id + key}>{getClearLabel(key)}</Label>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </Col>

                )
            })}

            <Col md="12" className="mt-4 notification-intervals">
                <div className="notification-intervals__title">
                    <h4>Notifications Snooze(hours)</h4>
                    <span id="intervalsInfo" className="notification-intervals__info">?</span>

                    <Tooltip
                        hideArrow={true}
                        placement="auto"
                        isOpen={tooltipOpened}
                        target={"intervalsInfo"}
                        toggle={() => setTooltipOpened(!tooltipOpened)}
                        innerClassName="notification-intervals__tooltip"
                    >
                        Notification frequencies prevent users from getting repeat nuisance notifications.
                        Users will not receive a notification for the same sensor and warning type until after the set frequency has passed.
                    </Tooltip>
                </div>

                <Row>
                    <RivataFormField
                        md={3}
                        type="number"
                        label={"Critical Alerts Interval"}
                        name={"critical_alert_interval_in_seconds"}
                        minHeight={80}
                        disabled={disabled}
                    />

                    <RivataFormField
                        md={3}
                        type="number"
                        label={"Info Alerts Interval"}
                        name={"info_alert_interval_in_seconds"}
                        minHeight={80}
                        disabled={disabled}
                    />
                </Row>
            </Col>
            <Col md="12" className="mt-2 notification-intervals">
                <div className="notification-intervals__title">
                    <h4>Combined Warning Types</h4>
                    <span id="combinedProfile" className="notification-intervals__info">?</span>

                    <Tooltip
                        hideArrow={true}
                        placement="auto"
                        isOpen={combinedTooltipOpened}
                        target={"combinedProfile"}
                        toggle={() => setCombinedToolitpOpened(!combinedTooltipOpened)}
                        innerClassName="notification-intervals__tooltip"
                    >
                        Notification will be sent only when all selected warnings types will be in Active state for asset.
                    </Tooltip>
                </div>

                <div className="profile-form__checkbox">
                    <Field id="is_combined" type="checkbox" name="is_combined" disabled={disabled} />
                    <Label for="is_combined">Is combined</Label>
                </div>


            </Col>
        </Row>
    )
}

export default React.memo(InputsGroup)
import baseApi from './BaseApi'

class AssetsApi {
  getAssets = (
    limit = 30,
    offset = 0,
    filterType?: string | null,
    filterData?: string | null,
    sortColumn?: string | null,
    sortDirection?: string | null,
    sensorType?: string | null,
    skipActiveSubscriptionCheck?: boolean,
    hideAutogeneratedAssets?: boolean,
    filters?: string | null,
  ) => {
    const params = new URLSearchParams()

    if (typeof limit === 'number') {
      params.append('limit', limit.toString())
    }

    if (typeof offset === 'number') {
      params.append('offset', offset.toString())
    }

    if (filterType) {
      params.append('filter_type', filterType)
    }

    if (filterData) {
      params.append('filter_data', filterData)
    }

    if (sortColumn) {
      params.append('sort_column', sortColumn)
    }

    if (sortDirection) {
      params.append('sort_direction', sortDirection)
    }

    if (sensorType) {
      params.append('sensor_type', sensorType)
    }

    if (skipActiveSubscriptionCheck) {
      params.append('skip_active_sub_check', 'true')
    }

    if (hideAutogeneratedAssets) params.append('hide_autogenerated', 'true')

    let queryString = params.toString()

    if (filters) queryString = `${queryString}&${filters}`

    return baseApi.get(`rivata/asset?${queryString}`)
  }

  getAssetsV2 = (query: string) => {
    return baseApi.get(`rivata/asset/v2?${query}`)
  }

  getExactAsset = (vin: string) => {
    return baseApi.get(`rivata/asset?exactvin=${vin}`, false)
  }

  checkIfAssetExistByVin = (vin: string) => {
    return baseApi.get(`rivata/asset/exist?vin=${vin}`, false)
  }

  getAssetStatuses = (assetType: string, assetId: number) => {
    const params = new URLSearchParams()

    if (typeof assetType === 'string') {
      params.append('asset_type', assetType)
    }

    if (typeof assetId === 'number') {
      params.append('asset_id', assetId.toString())
    }

    const queryString = params.toString()

    return baseApi.get(`rivata/asset/statuses?${queryString}`, false)
  }

  getAssetsLimitedData = (
    hideAutogeneratedAssets?: boolean,
    addCustomerIds = true,
    exactVins: string | undefined = undefined,
  ) => {
    const params = new URLSearchParams()

    params.append('get_limited_data', 'true')

    if (hideAutogeneratedAssets) params.append('hide_autogenerated', 'true')
    if (exactVins) params.append('exactvins', exactVins)

    const queryString = params.toString()

    return baseApi.get(`rivata/asset?${queryString}`, addCustomerIds)
  }

  postAsset = (data: any) => {
    return baseApi.post(`rivata/asset`, data)
  }

  putAsset = (data: any, username: string) => {
    return baseApi.put(
      `rivata/asset?asset_id=${data.id}&user_name=${username}`,
      data,
    )
  }

  deleteAsset = (id: number) => {
    return baseApi.delete(`rivata/asset?asset_id=${id}`)
  }

  migrateAssetBetweenCustomers = (data: any) => {
    return baseApi.post('rivata/asset/migrate', data)
  }

  searchAssets = (
    searchValue: string,
    searchField?: 'vin' | 'name',
    assetType?: 'tractor' | 'trailer',
    filterType?: string,
    filterData?: string,
    limit = 20,
    hideAutogeneratedAssets = true,
    customerIds?: string,
  ) => {
    const params = new URLSearchParams()

    params.append('search_value', searchValue)

    params.append('limit', limit.toString())

    if (searchField) params.append('search_field', searchField)

    if (assetType) params.append('asset_type', assetType)

    if (filterType) params.append('filter_type', filterType)
    if (filterData) params.append('filter_data', filterData)

    if (hideAutogeneratedAssets) params.append('hide_autogenerated', 'true')

    if (customerIds) params.append('customer_ids', customerIds)

    return baseApi.get(`rivata/asset/search?${params.toString()}`)
  }

  getAssetsCardsData = (query: string) => {
    return baseApi.get(`rivata/asset/cards?${query}`)
  }
  checkAssetsExistsByVins = (data: any) => {
    return baseApi.post(`rivata/asset/exist`, data)
  }
  postAssetsCsv = (
    base64csv: string,
    customerId: number,
    file_name: string,
  ) => {
    let queryString = `?customer_id=${customerId}`

    return baseApi.post(
      `rivata/asset/bulk_upload${queryString}&file_name=${file_name}`,
      base64csv,
    )
  }

  getAssetsBulkUploadHistory = (
    limit = 30,
    offset = 0,
    sortColumn?: string | null,
    sortDirection?: string | null,
  ) => {
    const params = new URLSearchParams()

    if (typeof limit === 'number') {
      params.append('limit', limit.toString())
    }

    if (typeof offset === 'number') {
      params.append('offset', offset.toString())
    }

    if (sortColumn) {
      params.append('sort_column', sortColumn)
    }

    if (sortDirection) {
      params.append('sort_direction', sortDirection)
    }

    const queryString = params.toString()
    return baseApi.get(`rivata/asset/bulk_upload/history?${queryString}`)
  }

  deleteAssets = (vins: Array<string>) => {
    return baseApi.delete(`rivata/asset?exactvins=${vins.join(',')}`)
  }

  updateAssetBulkUploadStatus = (bulk_upload_id: number, status: string) => {
    return baseApi.put(`rivata/asset/bulk_upload/status`, { bulk_upload_id: bulk_upload_id, status: status })
  }
}

const assetsApi = new AssetsApi()

export default assetsApi

import React from "react"
import DateTimePicker from "../../componentsV2/DateTimePicker"
import { CalendarTypes } from "../../enums"
import moment from "moment"
import { MenuItemType } from "../../componentsV2/Menu/MenuItem";
import ReportStatusFilter from "../../componentsV2/ReportStatus";
import ReportType from "../../componentsV2/ReportType";
interface ReportsHistoryGridFilterProps {
    dateFrom: Date;
    dateTo: Date;
    onDateRangeSelect: (from: Date, to: Date) => void;
    onReportStatusSelected: (id: string, selectedValue: MenuItemType[]) => void
    onReportTypeSelected: (id: string, selectedValue: MenuItemType[]) => void
}
const ReportsHistoryGridFilter: React.FC<ReportsHistoryGridFilterProps> = ({ dateFrom,
    dateTo,
    onDateRangeSelect, onReportStatusSelected, onReportTypeSelected }) => {
    return <>
        <div className="d-flex flex-wrap justify-content-between mb-2">
            <div className="d-flex flex-wrap align-items-start flex-column">
                <div className="d-flex flex-wrap flex-column">
                    <div>Select Date Range</div><DateTimePicker
                        defaultMode={CalendarTypes.DateRange}
                        dateFrom={dateFrom.toISOString()}
                        dateTo={dateTo.toISOString()}
                        onDateRangeSelect={onDateRangeSelect}
                        maxDate={moment().toDate()} disabled={false} />
                </div>
                <div className="d-flex flex-wrap mt-2">
                    <ReportStatusFilter onDropdownItemSelect={onReportStatusSelected} />
                    <ReportType className="ml-2" onDropdownItemSelect={onReportTypeSelected} />
                </div>
            </div>
        </div>
    </>
}
export default ReportsHistoryGridFilter
import { store } from '../store'
import api from '../../services/api'

import { convertDataEpochToDate } from '../../utils'
import { getStatusObj } from '../../utils/utils'

import { ISortOptions, IgetState } from '../types'
import { ActionableItemsFilter, ActionableItemsTypes } from './types'
import { Dispatch } from 'redux'

export const setActionableItemsOffset = (payload: number) => ({
  type: ActionableItemsTypes.SET_ACTIONABLE_ITEMS_OFFSET,
  payload,
})

export const setActionableItemsLimit = (payload: number) => ({
  type: ActionableItemsTypes.SET_ACTIONABLE_ITEMS_LIMIT,
  payload,
})

export const setActionableItemsDays = (payload: number) => ({
  type: ActionableItemsTypes.SET_ACTIONABLE_ITEMS_DAYS,
  payload,
})

export const setActionableItemsFilter = (payload: ActionableItemsFilter) => ({
  type: ActionableItemsTypes.SET_ACTIONABLE_ITEMS_FILTER,
  payload,
})

export const resetActionableItemsReducer = (payload: boolean) => ({
  type: ActionableItemsTypes.RESET_REDUCER,
  payload,
})
export const setActionableItemsSortOptions = (payload: ISortOptions) => ({
  type: ActionableItemsTypes.SET_ACTIONABLE_ITEMS_SORT_OPTIONS,
  payload: payload,
})
export const setResendEmallResult = (payload: Array<any>) => ({
  type: ActionableItemsTypes.SET_EMAIL_RESEND_RESULT,
  payload: payload,
})
export const setResendingEmails = (payload: boolean) => ({
  type: ActionableItemsTypes.SET_RESENDING_EMAILS,
  payload: payload,
})
export function fetchActionableItems() {
  return async (dispatch: Dispatch<any>, getState: IgetState) => {
    try {
      dispatch({
        type: ActionableItemsTypes.LOADING_ACTIONABLE_ITEMS,
        payload: true,
      })

      const { offset, limit, filter, days, sortOptions } =
        getState().actionableItems

      const daysRange =
        filter.warning_status?.length === 1 &&
        filter.warning_status.findIndex((s: any) => s.id === 'active') !== -1
          ? null
          : days

      const res = await api.getActionableItems(
        daysRange,
        offset,
        limit,
        filter,
        sortOptions,
      )

      let pagedData = convertDataEpochToDate(res.paged_data, 'epoch')
      pagedData = convertDataEpochToDate(
        pagedData,
        'recovered_to_normal_at_epoch',
        'recovered_to_normal_at_datetime',
      )
      pagedData = convertDataEpochToDate(
        pagedData,
        'canceled_at_epoch',
        'canceled_at_datetime',
      )
      pagedData.forEach((pd: any) => {
        pd['id'] = pagedData.indexOf(pd)
      })
      dispatch({
        type: ActionableItemsTypes.SET_ACTIONABLE_ITEMS_DATA,
        payload: pagedData,
      })
      dispatch({
        type: ActionableItemsTypes.SET_ACTIONABLE_ITEMS_TOTAL_COUNT,
        payload: res.total_count,
      })

      dispatch({
        type: ActionableItemsTypes.SET_ACTIONABLE_ITEMS_STATUS,
        payload: getStatusObj(),
      })
    } catch (err) {
      dispatch({
        type: ActionableItemsTypes.SET_ACTIONABLE_ITEMS_STATUS,
        payload: getStatusObj(err),
      })
      console.log(err)
    } finally {
      dispatch({
        type: ActionableItemsTypes.LOADING_ACTIONABLE_ITEMS,
        payload: false,
      })
    }
  }
}
export function postWarningsRequireActions(
  data: {
    keys: Array<string>
    actionable: boolean
  },
  onSuccess: any,
) {
  return async (dispatch: Dispatch<any>) => {
    try {
      await api.postWarningsRequireActions(data)

      dispatch(setActionableItemsOffset(0))
      if (onSuccess) {
        onSuccess()
      }
    } catch (err) {
      console.log('postWarningsRequireActions: ', err)
    }
  }
}
export function forceResendWarningEmail(
  data: {
    keys: Array<string>
  },
  onSuccess: any,
) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setResendingEmails(true))
      const res = await api.forceResendWarningEmail(data)
      dispatch(setResendEmallResult(res))
      if (onSuccess) {
        onSuccess()
      }
    } catch (err) {
      console.log('postWarningsRequireActions: ', err)
    } finally {
      dispatch(setResendingEmails(false))
    }
  }
}
store.subscribe(() => {
  const lastAction = store.getState().lastAction

  if (
    lastAction.type === ActionableItemsTypes.SET_ACTIONABLE_ITEMS_FILTER ||
    lastAction.type === ActionableItemsTypes.SET_ACTIONABLE_ITEMS_LIMIT ||
    lastAction.type === ActionableItemsTypes.SET_ACTIONABLE_ITEMS_DAYS ||
    lastAction.type === ActionableItemsTypes.SET_ACTIONABLE_ITEMS_SORT_OPTIONS
  ) {
    store.dispatch(setActionableItemsOffset(0))
  }

  if (lastAction.type === ActionableItemsTypes.SET_ACTIONABLE_ITEMS_OFFSET) {
    store.dispatch(fetchActionableItems())
  }
})

import React, { useCallback, useEffect, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useActions'
import RivataModule from '../../components/RivataModule'
import { useTableColumns, useTableRows } from './hooks'
import RivataTable from '../../components/RivataTable'
import { makeScrollToY } from '../../utils/utils'
import ActionableItemButton from '../../components/ActionableItemButton'
import { Modal, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap'
import SendActionableItemNotificationEmailButton from '../../components/SendActionableItemNotificationEmailButton'
import './styles.scss'
import { convertDataEpochToDate, getClearLabel } from '../../utils'
import RivataLoader from '../../components/RivataLoader'
import Filters from './Filters'

const ActionableItemsModule = () => {
  const {
    selectedCustomersList,
    locale,
    actionableItems: {
      data,
      isLoading,
      totalCount,
      offset,
      limit,
      resendEmailResult,
      resendingEmails,
    },
    unitsOfMeasurementConfig,
    isAdmin,
  } = useTypedSelector((state) => ({
    selectedCustomersList: state.common.customers.selectedCustomersList,
    locale: state.whitelabel.locale,
    actionableItems: state.actionableItems,
    unitsOfMeasurementConfig: state.auth.preferences.unitsOfMeasurementConfig,
    isAdmin: state.auth.isAdmin,
  }))
  const {
    setActionableItemsOffset,
    setActionableItemsLimit,
    setActionableItemsSortOptions,
    setActionableItemsFilter,
    postWarningsRequireActions,
    forceResendWarningEmail,
  } = useActions()

  const [selectedRows, setSelectedRows] = useState<Array<string>>([])
  const [selectAll, setSelectAll] = useState(false)
  const [showNotActionableSetModal, setShowNotActionableSetModal] =
    useState<boolean>(false)
  const [showEmailResentModal, setShowEmailResentModal] =
    useState<boolean>(false)
  const [showCustomerColumn, setShowCustomerColumn] = useState<boolean>(
    selectedCustomersList && selectedCustomersList.length > 1,
  )
  const columns = useTableColumns(selectAll, showCustomerColumn)
  const rows = useTableRows(
    data,
    locale,
    unitsOfMeasurementConfig,
    selectAll,
    selectedRows,
    showCustomerColumn,
  )
  useEffect(() => {
    setActionableItemsOffset(0)
    setSelectedRows([])
    setSelectAll(false)
    setShowCustomerColumn(
      selectedCustomersList && selectedCustomersList.length > 1,
    )
  }, [setActionableItemsOffset, selectedCustomersList])
  const handleLimitChange = useCallback(
    (limit: number) => {
      setActionableItemsLimit(limit)
      makeScrollToY(0)
      setSelectedRows([])
      setSelectAll(false)
    },
    [setActionableItemsLimit],
  )
  const onPageChange = useCallback(
    (offset: number) => {
      setActionableItemsOffset(offset)
      makeScrollToY(0)
      setSelectedRows([])
      setSelectAll(false)
    },
    [setActionableItemsOffset],
  )
  const onCheckRowColumn = (key: string, checked: boolean) => {
    setSelectAll(checked)

    const rows = data
    const selected = rows.map((el) => el.key)

    setSelectedRows(selected)
    if (!checked) {
      setSelectedRows([])
    }
  }

  const onCheckRowItem = (item: { id: number }) => {
    const { id } = item
    const rows = data
    const dataItem = rows.find((di: any) => {
      return di.id === id
    })
    if (dataItem) {
      const key = dataItem.key
      if (selectedRows.includes(key)) {
        const updatedSelected = selectedRows.filter((el) => el !== key)
        setSelectedRows(updatedSelected)
      } else if (selectAll) {
        const selected = rows.map((el) => el.key)
        setSelectedRows(selected)
      } else {
        let array = [...selectedRows, key]
        setSelectedRows(array)
      }
      setSelectAll(false)
    }
  }
  return (
    <RivataModule
      title='Action Required Events'
      locale={locale}
      filters={<></>}
    >
      {resendingEmails && <RivataLoader />}
      <div className='d-flex justify-content-between'>
        <div className='d-flex'>
          {isAdmin && data.length > 0 && (
            <>
              <ActionableItemButton
                btnClassName='mr-2 border mb-2'
                disabled={selectedRows.length === 0}
                makeActionable={false}
                locale={locale}
                onMakeActionable={() => {
                  postWarningsRequireActions(
                    { keys: selectedRows, actionable: false },
                    () => {
                      setShowNotActionableSetModal(true)
                      setSelectedRows([])
                      setSelectAll(false)
                    },
                  )
                }}
              />
              <SendActionableItemNotificationEmailButton
                btnClassName='mr-2 border mb-2'
                disabled={selectedRows.length === 0}
                locale={locale}
                onResendEmail={() => {
                  setSelectedRows([])
                  forceResendWarningEmail({ keys: selectedRows }, () => {
                    setShowEmailResentModal(true)
                    setSelectedRows([])
                    setSelectAll(false)
                  })
                }}
              />
            </>
          )}
        </div>
      </div>

      <Filters
        onFilter={setActionableItemsFilter}
        className='mb-3'
        disabled={isLoading}
      />

      <RivataTable
        showPagination={true}
        isShowingLimit={true}
        columns={columns}
        rows={rows}
        onDelete={undefined}
        isLoading={isLoading}
        onEdit={undefined}
        editDisabled={undefined}
        deleteDisabled={false}
        setSortOptions={setActionableItemsSortOptions}
        onCustomAction={undefined}
        totalCount={totalCount}
        // @ts-ignore component expect undefined cause of default value
        onSelectLimit={handleLimitChange}
        pageLimit={limit}
        page={offset / limit}
        // @ts-ignore component expect undefined cause of default value
        onPageChange={onPageChange}
        onCheckRowColumn={onCheckRowColumn}
        onCheckRowItem={onCheckRowItem}
      ></RivataTable>
      <Modal isOpen={showNotActionableSetModal}>
        <ModalHeader
          toggle={() => {
            setShowNotActionableSetModal(!showNotActionableSetModal)
          }}
        >
          Make not actionable
        </ModalHeader>
        <ModalBody>Selected warnig(s) set not actionable</ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              setShowNotActionableSetModal(!showNotActionableSetModal)
            }}
          >
            {locale['ok'] || 'Ok'}
          </Button>{' '}
        </ModalFooter>
      </Modal>
      <Modal
        size='lg'
        style={{ minWidth: '800px', width: '800px' }}
        isOpen={showEmailResentModal}
      >
        <ModalHeader
          toggle={() => {
            setShowEmailResentModal(!showEmailResentModal)
          }}
        >
          Send Email Notification
        </ModalHeader>
        <ModalBody>
          {resendEmailResult && resendEmailResult.length > 0 && (
            <div className='resend-warning-email-result'>
              <div className='resend-warning-email-row'>
                <div className='timestamp'>
                  <b>Timestamp</b>{' '}
                </div>
                <div className='position'>
                  <b>Position</b>{' '}
                </div>
                <div className='warning-type'>
                  <b>Warning Type</b>{' '}
                </div>
                <div className='asset-name'>
                  <b>Asset Name</b>
                </div>

                <div className='message'>
                  <b>Message</b>
                </div>
              </div>
              <div className='resend-warning-email-rows-wrapper'>
                {resendEmailResult.map((item: any) => {
                  return (
                    <div
                      className='resend-warning-email-row'
                      key={`${item.vin}_${item.message.replace(' ', '_')}_${
                        item.key
                      }`}
                    >
                      <div className='timestamp'>
                        {convertDataEpochToDate(
                          item.warning_epoch,
                          null,
                          null,
                          true,
                        )}
                      </div>
                      <div className='position'>
                        {getClearLabel(item.installment_position)}
                      </div>
                      <div className='warning-type'>
                        {getClearLabel(item.warning_type)}
                      </div>
                      <div className='asset-name'>{item.asset_name}</div>
                      <div className='message'>{item.message}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={() => {
              setShowEmailResentModal(!showEmailResentModal)
            }}
          >
            {locale['ok'] || 'Ok'}
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </RivataModule>
  )
}

export default ActionableItemsModule

import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Input } from 'reactstrap'
import moment from 'moment'
import DateTimePicker from '../../componentsV2/DateTimePicker'
import { CalendarTypes } from '../../enums'

import NotificationHistoryReportForm from './Forms/NotificationHistoryReportForm'
import GeofenceHistoryReportForm from './Forms/GeofenceHistoryReportForm'
import TagHistoryDetailsReportForm from './Forms/TagHistoryDetailsReportForm'
import AssetsInGeofenceReportForm from './Forms/AssetsInGeofenceReportForm'
import DurationInWarningStateReportForm from './Forms/DurationInWarningStateReportForm'
import DailyMileageReport from './Forms/DailyMileageReport'
import WarningHistoryReportForm from './Forms/WarningHistoryReportForm'
import AssetsSelect from './Forms/AssetsSelect'
import WarningFilterForm from './Forms/WarningFilterForm'
import DistanceTraveledReportForm from './Forms/DistanceTraveledReportForm'
import CargotagGatewayReport from './Forms/CargotagGatewayRepost'
import CargoTagsSelect from './Forms/CargoTagsSelect'
import UserLoginsReportForm from './Forms/UserLoginsReportForm'
import FailedNotificationHistoryReportForm from './Forms/FailedNotificationHistoryReportForm'
import DriverAppTripReportForm from './Forms/DriverAppTripReportForm'
import ScheduledReportsSubscriptionsReportForm from './Forms/ScheduledReportsSubscriptionsReportForm'

import { ReportTypes, TagReportTypes } from '../../enums'

import { useReportLink } from './hooks'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { getCurrentEpoch } from '../../utils'

import { sensorStatusItems } from '../../componentsV2/HealthStatusFilter/items'

import AssetGroupsFilter from '../../componentsV2/AssetGroupsFilter'
import HealthStatusFilter from '../../componentsV2/HealthStatusFilter'

import './style.scss'
import UtilizationReport from './Forms/UtilizationReport'
import NonReportingSensorsReportForm from './Forms/NonReportingSensorsReportForm'

import { cloneDeep } from 'lodash'
import DwellDaysInput from './Forms/DwellDaysInput'
import MilesInWarningStateReportForm from './Forms/MilesInWarningStateReportForm'
import NotificationsSubscriptionsReportForm from './Forms/NotificationsSubscriptionsReportForm'
const sersorStatusItemsFiltered = cloneDeep(sensorStatusItems).filter(
  (s) => s.id !== 'ok',
)
const sersorStatusItemsFilteredForWarningReports = cloneDeep(
  sersorStatusItemsFiltered,
).filter((s) => s.id !== 'no_status' && s.parentId !== 'no_status')
const noStatusItemsFiltered = cloneDeep(sensorStatusItems).filter(
  (s) => s.id === 'no_status' || s.parentId === 'no_status',
)

const ReportsFormSwitch = ({
  selectedReport,
  setReportLink,
  disabled,
  setDateRangeString,
  dropdown,
  isAll,
  locale = {},
}) => {
  const { selectedCustomersList } = useTypedSelector((state) => ({
    selectedCustomersList: state.common.customers.selectedCustomersList,
    locale: state.whitelabel.locale,
  }))

  const [dateFrom, setDateFrom] = useState(
    new Date(moment().clone().startOf('month').unix() * 1000),
  )
  const [dateTo, setDateTo] = useState(
    moment().hours(23).minutes(59).seconds(0).toDate(),
  )
  const [defaultDateFrom, setDefaultDateFrom] = useState(
    new Date(moment().clone().startOf('month').unix() * 1000),
  )
  const [defaultDateTo, setDefaultDateTo] = useState(
    moment().hours(23).minutes(59).seconds(0).toDate(),
  )
  const [timeFrom, setTimeFrom] = useState(null)
  const [timeTo, setTimeTo] = useState(null)
  const [assetType, setAssetType] = useState('all')
  const [vinList, setVinList] = useState([])
  const [macList, setMacList] = useState([])
  const [location, setLocation] = useState('all')
  const [usernames, setUsernames] = useState([])
  const [notificationTypes, setNotificationTypes] = useState({
    email: true,
    sms: true,
    push: true,
  })
  const [selectedGeofences, setSelectedGeofences] = useState([])
  const [lostTagDays, setLostTagDays] = useState(30)
  const [warningType, setWarningType] = useState('all')
  const [application, setApplication] = useState('all')
  const [selectedAssetGroups, setSelectedAssetGroups] = useState([])
  const [days, setDays] = useState(7)
  const [warningStatus, setWarningStatus] = useState('all')
  const [dwellDays, setDwellDays] = useState()
  const [reportTypeFilter, setReportTypeFilter] = useState()
  const [selectedGeofenceGroups, setSelectedGeofenceGroups] = useState([])

  useEffect(() => {
    let dtFrom = new Date(moment().startOf('month').unix() * 1000)
    let dtTo = moment().hours(23).minutes(59).seconds(0).toDate()
    let tFrom = null
    let tTo = null
    if (
      [
        ReportTypes.DISTANCE_TRAVELED_REPORT,
        ReportTypes.MILES_IN_WARNING_STATE_REPORT,
      ].includes(selectedReport)
    ) {
      dtFrom = new Date(
        moment().startOf('day').subtract(3, 'days').unix() * 1000,
      )
      tFrom = '00:00'
      tTo = '23:00'
    } else if (selectedReport === ReportTypes.UTILIZATION_REPORT) {
      const minDt = new Date('12/07/2023')
      dtFrom = dtFrom < minDt ? minDt : dtFrom
      dtTo = dtFrom < minDt ? minDt : dtTo
    }
    setDateFrom(dtFrom)
    setDateTo(dtTo)
    setTimeFrom(tFrom)
    setTimeTo(tTo)

    setDefaultDateFrom(dtFrom)
    setDefaultDateTo(dtTo)

    setAssetType('all')
    setVinList([])
    setLocation('all')
    setUsernames([])
    setNotificationTypes({ email: true, sms: true, push: true })
    setSelectedGeofences([])
    setLostTagDays(
      selectedCustomersList[0]?.preferences.cargo_tag_lost_tag_days ?? 30,
    )
    setMacList([])
    setApplication('all')
    setWarningType('all')
    setWarningStatus('all')
    setDwellDays()
  }, [selectedReport, selectedCustomersList])

  const reportLink = useReportLink(
    selectedReport,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    vinList.map((v) => v.vin),
    macList.map((tag) => tag.mac),
    usernames.map((u) => u.username),
    selectedGeofences.map((g) => g.id),
    assetType,
    notificationTypes,
    isAll,
    location,
    lostTagDays,
    warningType,
    application,
    selectedAssetGroups.map((a) => a.id),
    days,
    warningStatus,
    dwellDays,
    reportTypeFilter,
    selectedGeofenceGroups.map((a) => a.id),
  )

  useEffect(() => {
    setReportLink(reportLink)
  }, [reportLink, setReportLink])

  useEffect(() => {
    const dateRangeString = `${moment(dateFrom).format('L')} - ${moment(
      dateTo,
    ).format('L')}`

    setDateRangeString(dateRangeString)
  }, [dateFrom, dateTo, setDateRangeString])

  const onDateRangeSelect = useCallback(
    (from, to) => {
      setDateFrom(from)
      if (
        [
          ReportTypes.DURATION_IN_WARNING_STATE_REPORT,
          ReportTypes.UTILIZATION_REPORT,
          ReportTypes.MILES_IN_WARNING_STATE_REPORT,
        ].includes(selectedReport)
      ) {
        const diff = Math.abs(to.getTime() - from.getTime())
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24))
        if (diffDays > 90) {
          setDateTo(
            moment(from)
              .add(90, 'days')
              .hours(23)
              .minutes(59)
              .seconds(0)
              .toDate(),
          )
        } else {
          setDateTo(to)
        }
      } else {
        setDateTo(to)
      }
    },
    [setDateFrom, setDateTo, selectedReport],
  )

  const setMacStingsList = useCallback(
    (macs) => {
      const list = macs.map((mac) => {
        return { mac: mac }
      })
      setMacList(list)
    },
    [setMacList],
  )

  const assetTypes = useMemo(() => {
    return [
      { id: 'all', label: 'All', disabled: false },
      { id: 'tractor', label: 'Tractor', disabled: false },
      { id: 'trailer', label: 'Trailer', disabled: false },
    ]
  }, [])

  const warningTypes = useMemo(() => {
    return [
      { id: 'all', label: 'All', disabled: false },
      { id: 'warning', label: 'Warning', disabled: false },
      { id: 'no_status', label: 'No Status', disabled: false },
    ]
  }, [])

  const minDate = useMemo(() => {
    if (
      selectedReport === ReportTypes.DISTANCE_TRAVELED_REPORT ||
      selectedReport === ReportTypes.DAILY_MILEAGE_REPORT
    ) {
      return new Date('01/01/2022')
    }

    if (
      selectedReport === ReportTypes.WARNING_HISTORY_REPORT ||
      selectedReport === ReportTypes.NON_REPORTING_SENSORS_REPORT
    ) {
      const d = new Date(0)
      d.setUTCSeconds(getCurrentEpoch() - 60 * 60 * 24 * 90)

      return d
    }
    if (selectedReport === ReportTypes.UTILIZATION_REPORT) {
      return new Date('12/07/2023')
    }

    if (selectedReport === ReportTypes.VEHICLE_LOCATION_REPORT) {
      return new Date(new Date().setDate(new Date().getDate() - 90))
    }

    return null
  }, [selectedReport])

  const maxDate = useMemo(() => {
    const dtTo = moment().hours(23).minutes(59).seconds(0).toDate()
    if (selectedReport === ReportTypes.UTILIZATION_REPORT) {
      if (minDate > dtTo) return new Date('12/07/2023')
    }
    return dtTo
  }, [selectedReport, minDate])
  const onWarningItemSelectNew = useCallback((id, items) => {
    const checkedItems = items
      .filter((el) => el.checked && el.parentId !== null)
      .map((el) => el.id)
      .toString()
    setWarningType(checkedItems)
  }, [])

  const onAssetGroupFilterItemSelected = useCallback(
    (label, value) => {
      setSelectedAssetGroups(value)
    },
    [setSelectedAssetGroups],
  )
  const onSelectDays = useCallback((d) => {
    setDays(d)
  }, [])
  const onWarningStatusSelectNew = useCallback((id, items) => {
    const checkedItems = items
      .filter((el) => el.checked)
      .map((el) => el.id)
      .toString()
    setWarningStatus(checkedItems)
  }, [])

  const onGeofenceGroupFilterItemSelected = useCallback(
    (label, value) => {
      setSelectedGeofenceGroups(value)
    },
    [setSelectedGeofenceGroups],
  )
  switch (selectedReport) {
    case ReportTypes.USER_LOGINS_REPORT:
      return (
        <UserLoginsReportForm
          dateTo={dateTo}
          dateFrom={dateFrom}
          disabled={disabled}
          onDateRangeSelect={onDateRangeSelect}
          application={application}
          setApplication={setApplication}
        />
      )
    case ReportTypes.HARDWARE_COMMANDS_HISTORY_REPORT:
      return (
        <>
          <div className='mb-2'>Select Date Range</div>
          <DateTimePicker
            defaultMode={CalendarTypes.DateRange}
            dateFrom={dateFrom.toISOString()}
            dateTo={dateTo.toISOString()}
            onDateRangeSelect={onDateRangeSelect}
            maxDate={moment().toDate()}
            disabled={disabled}
            defaultDateFrom={defaultDateFrom}
            defaultDateTo={defaultDateTo}
          />
        </>
      )
    case ReportTypes.DEVICE_HEALTH_REPORT:
      return (
        <>
          <AssetsSelect
            disabled={disabled}
            assetTypes={assetTypes}
            selectedAssetType={assetType}
            setSelectedAssetType={setAssetType}
            setVinList={setVinList}
            selectedItems={vinList}
          />
          <AssetGroupsFilter
            className='mt-3'
            onDropdownItemSelect={onAssetGroupFilterItemSelected}
            customerIds={selectedCustomersList.map((c) => c.id).join(',')}
          />
        </>
      )
    case ReportTypes.ODOMETER_OFFSET_SUMMARY_REPORT:
      return (
        <>
          <DailyMileageReport
            onDateRangeSelect={onDateRangeSelect}
            setVinList={setVinList}
            dateFrom={dateFrom}
            dateTo={dateTo}
            disabled={disabled}
            selectedAssetsItems={vinList}
          />
          <AssetGroupsFilter
            className='mt-3'
            onDropdownItemSelect={onAssetGroupFilterItemSelected}
            customerIds={selectedCustomersList.map((c) => c.id).join(',')}
          />
        </>
      )
    case ReportTypes.DAILY_MILEAGE_REPORT:
      return (
        <DailyMileageReport
          onDateRangeSelect={onDateRangeSelect}
          setVinList={setVinList}
          dateFrom={dateFrom}
          dateTo={dateTo}
          minDate={minDate}
          disabled={disabled}
          selectedAssetsItems={vinList}
        />
      )
    case ReportTypes.GEOFENCE_HISTORY_REPORT:
      return (
        <>
          <GeofenceHistoryReportForm
            disabled={disabled}
            dateFrom={dateFrom}
            dateTo={dateTo}
            assetTypes={assetTypes}
            selectedAssetType={assetType}
            vinList={vinList}
            selectedGeofences={selectedGeofences}
            onDateRangeSelect={onDateRangeSelect}
            setSelectedAssetType={setAssetType}
            setVinList={setVinList}
            setSelectedGeofences={setSelectedGeofences}
            selectedAssetsItems={vinList}
            selectedGeofenceItems={selectedGeofences}
            onAssetGroupFilterItemSelected={onAssetGroupFilterItemSelected}
            customerIds={selectedCustomersList.map((c) => c.id).join(',')}
            showAssetGroupsFilter={true}
            onGeofenceGroupFilterItemSelected={
              onGeofenceGroupFilterItemSelected
            }
          />
        </>
      )
    case ReportTypes.DISTANCE_TRAVELED_REPORT:
    case ReportTypes.VEHICLE_LOCATION_REPORT:
      return (
        <>
          <DistanceTraveledReportForm
            dateFrom={dateFrom}
            dateTo={dateTo}
            minDate={minDate}
            onDateRangeSelect={onDateRangeSelect}
            timeFrom={timeFrom}
            timeTo={timeTo}
            setTimeFrom={setTimeFrom}
            setTimeTo={setTimeTo}
            assetTypes={assetTypes}
            selectedAssetType={assetType}
            setAssetType={setAssetType}
            selectedVinList={vinList}
            setVinList={setVinList}
            disabled={disabled}
          />
          <AssetGroupsFilter
            className='mt-3'
            onDropdownItemSelect={onAssetGroupFilterItemSelected}
            customerIds={selectedCustomersList.map((c) => c.id).join(',')}
          />
        </>
      )
    case TagReportTypes.LATEST_TAG_LOCATION_REPORT:
      const isDisabled = isAll ? isAll : disabled
      return (
        <>
          {isAll && (
            <span>
              Report will show all tags regardless of when they last reported
            </span>
          )}
          {!isAll && (
            <DateTimePicker
              defaultMode={CalendarTypes.DateRange}
              dateFrom={dateFrom}
              dateTo={dateTo}
              onDateRangeSelect={onDateRangeSelect}
              timeEnabled={true}
              maxDate={moment().toDate()}
              disabled={isDisabled}
              defaultDateFrom={defaultDateFrom}
              defaultDateTo={defaultDateTo}
            />
          )}
          <CargoTagsSelect
            selectedCargoTags={macList}
            setSelectedCargoTags={setMacList}
            disabled={disabled}
          />
        </>
      )

    case TagReportTypes.UNASSIGNED_CARGO_TAGS_REPORT:
      return (
        <CargoTagsSelect
          selectedCargoTags={macList}
          setSelectedCargoTags={setMacList}
          disabled={disabled}
        />
      )

    case TagReportTypes.TAG_HISTORY_DETAILS_REPORT:
      return (
        <TagHistoryDetailsReportForm
          dateFrom={dateFrom}
          dateTo={dateTo}
          locations={dropdown}
          location={location}
          onDateRangeSelect={onDateRangeSelect}
          selectedCargoTags={macList}
          setSelectedCargoTags={setMacList}
          setLocation={setLocation}
          disabled={disabled}
        />
      )
    case ReportTypes.ASSET_PROVISIONING_SUMMARY_REPORT:
      return (
        <AssetsSelect
          disabled={disabled}
          setVinList={setVinList}
          selectedItems={vinList}
        />
      )
    case ReportTypes.WARNING_HISTORY_REPORT:
      return (
        <>
          <WarningHistoryReportForm
            dateFrom={dateFrom}
            dateTo={dateTo}
            defaultDateFrom={defaultDateFrom}
            defaultDateTo={defaultDateTo}
            minDate={minDate}
            disabled={disabled}
            onDateRangeSelect={onDateRangeSelect}
            setVinList={setVinList}
            setSelectedGeofences={setSelectedGeofences}
            selectedAssetsItems={vinList}
            selectedGeofenceItems={selectedGeofences}
            onWarningItemSelectNew={onWarningItemSelectNew}
            sersorStatusItemsFiltered={
              sersorStatusItemsFilteredForWarningReports
            }
            onAssetGroupFilterItemSelected={onAssetGroupFilterItemSelected}
            customerIds={selectedCustomersList.map((c) => c.id).join(',')}
            showAssetGroupsFilter={true}
            onGeofenceGroupFilterItemSelected={
              onGeofenceGroupFilterItemSelected
            }
          />
        </>
      )
    case ReportTypes.NOTIFICATION_HISTORY_REPORT:
      return (
        <>
          <NotificationHistoryReportForm
            disabled={disabled}
            dateFrom={dateFrom}
            dateTo={dateTo}
            notificationTypes={notificationTypes}
            onDateRangeSelect={onDateRangeSelect}
            setNotificationTypes={setNotificationTypes}
            setUsernames={setUsernames}
            setVinList={setVinList}
            selectedAssetsItems={vinList}
            selectedUsersItems={usernames}
          />
          <AssetGroupsFilter
            className='mt-3'
            onDropdownItemSelect={onAssetGroupFilterItemSelected}
            customerIds={selectedCustomersList.map((c) => c.id).join(',')}
          />
        </>
      )
    case ReportTypes.FAILED_NOTIFICATION_HISTORY_REPORT:
      return (
        <FailedNotificationHistoryReportForm
          disabled={disabled}
          setUsernames={setUsernames}
          selectedUsersItems={usernames}
          locale={locale}
          onSelectDays={onSelectDays}
        />
      )
    case ReportTypes.TPMS_REPORT:
      return (
        <>
          <WarningFilterForm
            disabled={disabled}
            warningTypes={warningTypes}
            selectedWarningType={warningType}
            setSelectedWarningType={setWarningType}
          />
          <AssetsInGeofenceReportForm
            disabled={disabled}
            assetTypes={assetTypes}
            selectedAssetType={assetType}
            setSelectedAssetType={setAssetType}
            setVinList={setVinList}
            setSelectedGeofences={setSelectedGeofences}
            selectedAssetsItems={vinList}
            selectedGeofenceItems={selectedGeofences}
            onAssetGroupFilterItemSelected={onAssetGroupFilterItemSelected}
            customerIds={selectedCustomersList.map((c) => c.id).join(',')}
            showAssetGroupsFilter={true}
            onGeofenceGroupFilterItemSelected={
              onGeofenceGroupFilterItemSelected
            }
          />
        </>
      )
    case ReportTypes.ACTIVE_WARNINGS_REPORT:
      return (
        <>
          <HealthStatusFilter
            id='warning_type_new'
            className='mr-2'
            overrideDefaultItems={sersorStatusItemsFilteredForWarningReports}
            onDropdownItemSelect={onWarningItemSelectNew}
            disabled={disabled}
          />
          <AssetsInGeofenceReportForm
            disabled={disabled}
            assetTypes={assetTypes}
            selectedAssetType={assetType}
            setSelectedAssetType={setAssetType}
            setVinList={setVinList}
            setSelectedGeofences={setSelectedGeofences}
            selectedAssetsItems={vinList}
            selectedGeofenceItems={selectedGeofences}
            onAssetGroupFilterItemSelected={onAssetGroupFilterItemSelected}
            customerIds={selectedCustomersList.map((c) => c.id).join(',')}
            showAssetGroupsFilter={true}
            onGeofenceGroupFilterItemSelected={
              onGeofenceGroupFilterItemSelected
            }
          />
        </>
      )
    case ReportTypes.DURATION_IN_WARNING_STATE_REPORT:
      return (
        <>
          <div className='mb-2'>Select Date Range</div>
          <DateTimePicker
            defaultMode={CalendarTypes.DateRange}
            dateFrom={dateFrom.toISOString()}
            dateTo={dateTo.toISOString()}
            onDateRangeSelect={onDateRangeSelect}
            maxDate={moment().toDate()}
            disabled={disabled}
            defaultDateFrom={defaultDateFrom}
            defaultDateTo={defaultDateTo}
          />
          <HealthStatusFilter
            id='warning_type_new'
            className='mt-4 mr-2'
            overrideDefaultItems={sersorStatusItemsFilteredForWarningReports}
            onDropdownItemSelect={onWarningItemSelectNew}
            disabled={disabled}
          />
          <DurationInWarningStateReportForm
            disabled={disabled}
            assetTypes={assetTypes}
            selectedAssetType={assetType}
            setSelectedAssetType={setAssetType}
            setVinList={setVinList}
            selectedAssetsItems={vinList}
            onAssetGroupFilterItemSelected={onAssetGroupFilterItemSelected}
            customerIds={selectedCustomersList.map((c) => c.id).join(',')}
            showAssetGroupsFilter={true}
          />
        </>
      )
    case ReportTypes.DWELL_REPORT:
      return (
        <>
          <AssetsInGeofenceReportForm
            disabled={disabled}
            assetTypes={assetTypes}
            selectedAssetType={assetType}
            setSelectedAssetType={setAssetType}
            setVinList={setVinList}
            setSelectedGeofences={setSelectedGeofences}
            selectedAssetsItems={vinList}
            selectedGeofenceItems={selectedGeofences}
            onAssetGroupFilterItemSelected={onAssetGroupFilterItemSelected}
            customerIds={selectedCustomersList.map((c) => c.id).join(',')}
            showAssetGroupsFilter={true}
            onGeofenceGroupFilterItemSelected={
              onGeofenceGroupFilterItemSelected
            }
          />
          <DwellDaysInput
            dwellDays={dwellDays}
            disabled={disabled}
            onChange={setDwellDays}
            width={400}
          />
        </>
      )
    case ReportTypes.ASSETS_IN_GEOFENCE_REPORT:
      return (
        <AssetsInGeofenceReportForm
          disabled={disabled}
          assetTypes={assetTypes}
          selectedAssetType={assetType}
          setSelectedAssetType={setAssetType}
          setVinList={setVinList}
          setSelectedGeofences={setSelectedGeofences}
          selectedAssetsItems={vinList}
          selectedGeofenceItems={selectedGeofences}
          onAssetGroupFilterItemSelected={onAssetGroupFilterItemSelected}
          customerIds={selectedCustomersList.map((c) => c.id).join(',')}
          showAssetGroupsFilter={true}
          onGeofenceGroupFilterItemSelected={onGeofenceGroupFilterItemSelected}
        />
      )

    case TagReportTypes.LOST_TAGS_REPORT:
      return (
        <>
          <label> Lost tag number of days threshold </label>

          <Input
            type='number'
            className='col-1'
            value={lostTagDays}
            onChange={(e) => setLostTagDays(e.target.value)}
            disabled={disabled}
          />
        </>
      )
    case TagReportTypes.CARGO_TAG_GATEWAY_REPORT:
      return (
        <CargotagGatewayReport
          disabled={disabled}
          selectedGeofenceItems={selectedGeofences}
          setMacList={setMacStingsList}
          onGeofencesSelect={setSelectedGeofences}
        />
      )
    case ReportTypes.DRIVER_APP_TRIP_REPORT:
      return (
        <DriverAppTripReportForm
          disabled={disabled}
          onDateRangeSelect={onDateRangeSelect}
          dateFrom={dateFrom}
          dateTo={dateTo}
          setVinList={setVinList}
          selectedAssetsItems={vinList}
          setUsernames={setUsernames}
          selectedUsersItems={usernames}
          onAssetGroupFilterItemSelected={onAssetGroupFilterItemSelected}
          customerIds={selectedCustomersList.map((c) => c.id).join(',')}
        />
      )

    case ReportTypes.UTILIZATION_REPORT:
      return (
        <UtilizationReport
          onDateRangeSelect={onDateRangeSelect}
          setVinList={setVinList}
          dateFrom={dateFrom}
          dateTo={dateTo}
          disabled={disabled}
          selectedAssetsItems={vinList}
          onAssetGroupFilterItemSelected={onAssetGroupFilterItemSelected}
          customerIds={selectedCustomersList.map((c) => c.id).join(',')}
          maxDate={maxDate}
          minDate={minDate}
          assetTypes={assetTypes}
          selectedAssetType={assetType}
          setSelectedAssetType={setAssetType}
        />
      )
    case ReportTypes.NON_REPORTING_SENSORS_REPORT:
      return (
        <>
          <NonReportingSensorsReportForm
            dateFrom={dateFrom}
            dateTo={dateTo}
            defaultDateFrom={defaultDateFrom}
            defaultDateTo={defaultDateTo}
            minDate={minDate}
            disabled={disabled}
            onDateRangeSelect={onDateRangeSelect}
            setVinList={setVinList}
            setSelectedGeofences={setSelectedGeofences}
            selectedAssetsItems={vinList}
            selectedGeofenceItems={selectedGeofences}
            onWarningItemSelectNew={onWarningItemSelectNew}
            sersorStatusItemsFiltered={noStatusItemsFiltered}
            onAssetGroupFilterItemSelected={onAssetGroupFilterItemSelected}
            customerIds={selectedCustomersList.map((c) => c.id).join(',')}
            onWarningStatusSelectNew={onWarningStatusSelectNew}
            onGeofenceGroupFilterItemSelected={
              onGeofenceGroupFilterItemSelected
            }
          />
        </>
      )
    case ReportTypes.MILES_IN_WARNING_STATE_REPORT:
      return (
        <MilesInWarningStateReportForm
          dateFrom={dateFrom}
          dateTo={dateTo}
          onDateRangeSelect={onDateRangeSelect}
          sersorStatusItemsFiltered={sersorStatusItemsFilteredForWarningReports}
          onWarningItemSelectNew={onWarningItemSelectNew}
          assetTypes={assetTypes}
          selectedAssetType={assetType}
          setSelectedAssetType={setAssetType}
          selectedAssetsItems={vinList}
          setVinList={setVinList}
          customerIds={selectedCustomersList.map((c) => c.id).join(',')}
          onAssetGroupFilterItemSelected={onAssetGroupFilterItemSelected}
          disabled={disabled}
        />
      )
    case ReportTypes.SCHEDULED_REPORTS_SUBSCRIPTIONS_REPORT:
      return (
        <ScheduledReportsSubscriptionsReportForm
          disabled={disabled}
          selectedUsersItems={usernames}
          setUsernames={setUsernames}
          reportTypeFilter={reportTypeFilter}
          setReportTypeFilter={setReportTypeFilter}
        />
      )
    case ReportTypes.NOTIFICATIONS_SUBSCRIPTIONS_REPORT:
      return (
        <NotificationsSubscriptionsReportForm
          disabled={disabled}
          setUsernames={setUsernames}
          selectedUsersItems={usernames}
          notificationTypes={notificationTypes}
          setNotificationTypes={setNotificationTypes}
          sersorStatusItemsFiltered={sersorStatusItemsFilteredForWarningReports}
          onWarningItemSelectNew={onWarningItemSelectNew}
        />
      )
    default:
      return null
  }
}

export default ReportsFormSwitch

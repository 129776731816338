import { MenuItemType } from '../../componentsV2/Menu/MenuItem'
import { DropdownFilterType } from '../../modules/RecentWarnings'
import {
  ISortOptions,
  SetBoolean,
  SetLoading,
  SetNumber,
  SetSortOptions,
  SetStatus,
} from '../types'

export enum ActionableItemsTypes {
  LOADING_ACTIONABLE_ITEMS = 'LOADING_ACTIONABLE_ITEMS',
  SET_ACTIONABLE_ITEMS_DATA = 'SET_ACTIONABLE_ITEMS_DATA',
  SET_ACTIONABLE_ITEMS_LIMIT = 'SET_ACTIONABLE_ITEMS_LIMIT',
  SET_ACTIONABLE_ITEMS_OFFSET = 'SET_ACTIONABLE_ITEMS_OFFSET',
  SET_ACTIONABLE_ITEMS_TOTAL_COUNT = 'SET_ACTIONABLE_ITEMS_COUNT',
  SET_ACTIONABLE_ITEMS_FILTER = 'SET_ACTIONABLE_ITEMS_FILTER',
  SET_ACTIONABLE_ITEMS_STATUS = 'SET_ACTIONABLE_ITEMS_STATUS',
  SET_ACTIONABLE_ITEMS_DAYS = 'SET_ACTIONABLE_ITEMS_DAYS',
  SET_ACTIONABLE_ITEMS_SORT_OPTIONS = 'SET_ACTIONABLE_ITEMS_SORT_OPTIONS',

  SET_EMAIL_RESEND_RESULT = 'SET_EMAIL_RESEND_RESULT',
  SET_RESENDING_EMAILS = 'SET_RESENDING_EMAILS',

  RESET_REDUCER = 'RESET_ACTIONABLE_ITEMS_REDUCER',
}

export type ActionableItemsFilter = {
  warning_status?: Array<string>
  sensor_type?: Array<string>
  warning_type?: Array<string>
  position_type?: Array<string>
  asset_name_vin?: Array<string>
}
export interface IActionableItem extends IAssetWarning {
  asset_name: string
  vin: string
}
export interface ActionableItemsReducer {
  isLoading: boolean
  data: Array<IActionableItem>
  totalCount: number
  limit: number
  offset: number
  filter: ActionableItemsFilter
  days: number
  status: IStatus
  sortOptions: ISortOptions
  resendEmailResult: Array<any>
  resendingEmails: boolean
}

export interface SetActionableWarningFilter<Type> {
  type: Type
  payload: ActionableItemsFilter
}

type LoadingActionableItems = SetLoading<
  | ActionableItemsTypes.LOADING_ACTIONABLE_ITEMS
  | ActionableItemsTypes.SET_RESENDING_EMAILS
>

type SetActionableItemsNumber = SetNumber<
  | ActionableItemsTypes.SET_ACTIONABLE_ITEMS_LIMIT
  | ActionableItemsTypes.SET_ACTIONABLE_ITEMS_OFFSET
  | ActionableItemsTypes.SET_ACTIONABLE_ITEMS_TOTAL_COUNT
  | ActionableItemsTypes.SET_ACTIONABLE_ITEMS_DAYS
>

type SetActionableItemsFilter =
  SetActionableWarningFilter<ActionableItemsTypes.SET_ACTIONABLE_ITEMS_FILTER>
type SetActionableItemsStatus =
  SetStatus<ActionableItemsTypes.SET_ACTIONABLE_ITEMS_STATUS>
type SetBool = SetBoolean<ActionableItemsTypes.RESET_REDUCER>
type SetActionableItemsSortOrder =
  SetSortOptions<ActionableItemsTypes.SET_ACTIONABLE_ITEMS_SORT_OPTIONS>

interface ISetActionableItems {
  type: ActionableItemsTypes.SET_ACTIONABLE_ITEMS_DATA
  payload: Array<IActionableItem>
}
interface ISetResendEmailResult {
  type: ActionableItemsTypes.SET_EMAIL_RESEND_RESULT
  payload: Array<any>
}
export type ActionableItemsActions =
  | LoadingActionableItems
  | SetActionableItemsNumber
  | SetBool
  | SetActionableItemsStatus
  | ISetActionableItems
  | SetActionableItemsFilter
  | SetActionableItemsSortOrder
  | ISetResendEmailResult

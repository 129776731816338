import { AnnotationOptions } from "chartjs-plugin-annotation"
import { SetBoolean, SetLoading, SetNumber, SetStatus } from "../types"
import { DropdownFilterType } from "../../modules/RecentWarnings"
import { MenuItemType } from "../../componentsV2/Menu/MenuItem"


export interface IHardwareInfo {
    id: number
    hardware_type: string
    hardware_type_id: number
    position: string | null
    mac: string
    info: null | {
        device_info: {
            type: string
            iccid: string
            retry_count: number
        }
    }
    last_updated_at_epoch: number | null
    last_update: string | null
    formatted_last_update: string | null
    prev_update_at_epoch: number | null
    position_type: string | null
    gateway_mac: string | null
    asset_id: number
    vin: string
}

export interface IVIbrationData {
    [key: string]: Array<[ number, number, number, number, number ]>
}

type ITirePressureData = Array<[ number, string, Array<[ number, number ]> ]>
type ITemperatureData = ITirePressureData // same data format
interface IAxleLoadData {
    bucket_epoch: number
    pressure: number
    speed: number
    weight: number
}

interface ILinePressureData extends Pick<IAxleLoadData, "bucket_epoch" | "speed"> {
    min_value: number
}

interface IMotionAnnotations { [key: string]: AnnotationOptions<"box"> }

export type Timespan = { epoch_end: number, epoch_start: number, value: number }

export interface IPowerInputs {
    [key: string]: Array<Timespan>
    ignition: Array<Timespan>
    brake_circuit:  Array<Timespan>
    external_power:  Array<Timespan>
    accelerometer_motion:  Array<Timespan>
    powered_by_internal_battery:  Array<Timespan>
    internal_battery_charge_is_low:  Array<Timespan>
    internal_battery_voltage:  Array<Timespan>
    external_voltage:  Array<Timespan>
}

export interface IGpsData {
    epoch: number
    latitude: number
    longitude: number
    speed: number
    heading: number
    elevation: number
    hdop: number
    warning_info: {
        has_warning: boolean
        details: Array<{ position: string, key: string, cause: string, timestamp: number }>
    }
    datetime: any
    formatted_datetime: string
}

export interface IChartXRange { min: number | null, max: number | null }


export enum AssetDetailsTypes {
    LOADING_ASSET_INFO = "LOADING_ASSET_DETAILS_ASSET_INFO",
    SET_ASSET_INFO = "SET_ASSET_DETAILS_ASSET_INFO_DATA",
    SET_ASSET_INFO_STATUS = "SET_ASSET_DETAILS_ASSET_INFO_STATUS",

    LOADING_SENSOR_INFO = "LOADING_ASSET_DETAILS_SENSOR_INFO",
    SET_SENSOR_INFO = "SET_ASSET_DETAILS_SENSOR_INFO_DATA",
    SET_SENSOR_INFO_STATUS = "SET_ASSET_DETAILS_SENSOR_INFO_STATUS",

    LOADING_STATUS_DIAGRAM_INFO = "LOADING_ASSET_DETAILS_STATUS_DIAGRAM",
    SET_STATUS_DIAGRAM = "SET_ASSET_DETAILS_STATUS_DIAGRAM_DATA",
    SET_STATUS_DIAGRAM_STATUS = "SET_ASSET_DETAILS_STATUS_DIAGRAM_STATUS",

    LOADING_WARNINGS = "LOADING_ASSET_DETAILS_WARNINGS",
    LOADING_WARNINGS_ACKNOWLEDGE = "LOADING_ASSET_DETAILS_WARNINGS_ACKNOWLEDGE",
    SET_WARNINGS = "SET_ASSET_DETAILS_WARNINGS_DATA",
    SET_WARNINGS_LIMIT = "SET_ASSET_DETAILS_WARNINGS_LIMIT",
    SET_WARNINGS_OFFSET = "SET_ASSET_DETAILS_WARNINGS_OFFSET",
    SET_WARNINGS_TOTAL_COUNT = "SET_ASSET_DETAILS_WARNINGS_COUNT",
    SET_WARNINGS_ACKNOWLEDGED_COUNT = "SET_ASSET_DETAILS_WARNINGS_ACKNOWLEDGED_COUNT",
    SET_WARNINGS_FILTER = "SET_ASSET_DETAILS_WARNINGS_FILTER",
    SET_WARNINGS_STATUS = "SET_ASSET_DETAILS_WARNINGS_STATUS",
    SET_SHOW_ALL_WARNINGS = "SET_ASSET_DETAILS_WARNINGS_SHOW_ALL",
    SET_WARNINGS_DAYS = "SET_ASSET_DETAILS_WARNINGS_DAYS",

    LOADING_VIBRATIONS = "LOADING_ASSET_DETAILS_VIBRATIONS",
    SET_VIBRATIONS = "SET_ASSET_DETAILS_VIBRATIONS_DATA",
    SET_VIBRATIONS_STATUS = "SET_ASSET_DETAILS_VIBRATIONS_STATUS",

    LOADING_TIRE_PRESSURE = "LOADING_ASSET_DETAILS_TIRE_PRESSURE",
    SET_TIRE_PRESSURE = "SET_ASSET_DETAILS_TIRE_PRESSURE_DATA",
    SET_TIRE_PRESSURE_STATUS = "SET_ASSET_DETAILS_TIRE_PRESSURE_STATUS",

    LOADING_AXLE_LOAD = "LOADING_ASSET_DETAILS_AXLE_LOAD",
    SET_AXLE_LOAD = "SET_ASSET_DETAILS_AXLE_LOAD_DATA",
    SET_AXLE_LOAD_STATUS = "SET_ASSET_DETAILS_AXLE_LOAD_STATUS",
    SET_AXLE_LOAD_CALIBRATIONS_COUNT = "SET_ASSET_DETAILS_AXLE_LOAD_CALIBRATIONS_COUNT",

    LOADING_LINE_PRESSURE = "LOADING_ASSET_DETAILS_LINE_PRESSURE",
    SET_LINE_PRESSURE = "SET_ASSET_DETAILS_LINE_PRESSURE_DATA",
    SET_LINE_PRESSURE_STATUS = "SET_ASSET_DETAILS_LINE_PRESSURE_STATUS",

    SET_MOTION_ANNOTATIONS = "SET_ASSET_DETAILS_MOTION_ANNOTATIONS",

    LOADING_TEMPERATURE = "LOADING_ASSET_DETAILS_TEMPERATURE",
    SET_TEMPERATURE = "SET_ASSET_DETAILS_TEMPERATURE_DATA",
    SET_TEMPERATURE_STATUS = "SET_ASSET_DETAILS_TEMPERATURE_STATUS",

    LOADING_POWER_INPUTS = "LOADING_ASSET_DETAILS_POWER_INPUTS",
    SET_POWER_INPUTS = "SET_ASSET_DETAILS_POWER_INPUTS_DATA",
    SET_POWER_INPUTS_STATUS = "SET_ASSET_DETAILS_POWER_INPUTS_STATUS",

    LOADING_GPS = "LOADING_ASSET_DETAILS_GPS",
    SET_GPS = "SET_ASSET_DETAILS_GPS_DATA",
    SET_GPS_STATUS = "SET_ASSET_DETAILS_GPS_STATUS",

    SET_ASSIGNED_TPMS_PROFILE = "SET_ASSET_DETAILS_ASSIGNED_TPMS_PROFILE",

    SET_CHARTS_X_RANGE = "SET_ASSET_DETAILS_CHARTS_X_RANGE",

    RESET_REDUCER = "RESET_ASSET_DETAILS_REDUCER"
}

export interface AssetDetailsReducer {
    assetInfo: { isLoading: boolean, data: IAssetInfo | null, status: IStatus },
    sensorInfo: { isLoading: boolean, data: Array<IHardwareInfo>, status: IStatus },
    statuses: { isLoading: boolean, data: any, status: IStatus },
    recentWarnings: {
        isLoading: boolean
        loadingWarningAcknowledge: boolean
        data: Array<IAssetWarning>
        totalCount: number
        acknowledgedWarningsCount: number
        limit: number
        offset: number
        showAllWarnings: boolean
        filter: Record<DropdownFilterType, Array<MenuItemType>>
        days: number
        status: IStatus
    },
    gps: {
        isLoading: boolean
        data: Array<IGpsData>
        status: IStatus
    },
    vibrations: { isLoading: boolean, data: IVIbrationData, status: IStatus },
    tirePressure: { isLoading: boolean, data: ITirePressureData, status: IStatus },
    axleLoad: { isLoading: boolean, data: Array<IAxleLoadData>, calibrationsCount: number | null, status: IStatus },
    linePressure: { isLoading: boolean, data: Array<ILinePressureData>, status: IStatus },
    temperature: { isLoading: boolean, data: Array<ITemperatureData>, status: IStatus },
    powerInputs: { isLoading: boolean, data: IPowerInputs | null, status: IStatus },
    motionAnnotations: IMotionAnnotations,
    assignedTpmsProfile: ITpmsProfile | null,
    chartsXRange: IChartXRange
}

export interface SetWarningFilter<Type> {
    type: Type
    payload: Record<DropdownFilterType, Array<MenuItemType>>
}

type LoadingAssetDetails = SetLoading<
    AssetDetailsTypes.LOADING_WARNINGS | 
    AssetDetailsTypes.LOADING_WARNINGS_ACKNOWLEDGE | 
    AssetDetailsTypes.LOADING_AXLE_LOAD |
    AssetDetailsTypes.LOADING_ASSET_INFO | 
    AssetDetailsTypes.LOADING_GPS | 
    AssetDetailsTypes.LOADING_LINE_PRESSURE | 
    AssetDetailsTypes.LOADING_POWER_INPUTS |
    AssetDetailsTypes.LOADING_SENSOR_INFO | 
    AssetDetailsTypes.LOADING_TEMPERATURE | 
    AssetDetailsTypes.LOADING_TIRE_PRESSURE | 
    AssetDetailsTypes.LOADING_VIBRATIONS | 
    AssetDetailsTypes.LOADING_STATUS_DIAGRAM_INFO
>

type SetAssetDetailsNumber = SetNumber<
    AssetDetailsTypes.SET_WARNINGS_LIMIT | 
    AssetDetailsTypes.SET_WARNINGS_OFFSET | 
    AssetDetailsTypes.SET_WARNINGS_TOTAL_COUNT | 
    AssetDetailsTypes.SET_WARNINGS_ACKNOWLEDGED_COUNT | 
    AssetDetailsTypes.SET_WARNINGS_DAYS
>

type SetWarningsFilter = SetWarningFilter<AssetDetailsTypes.SET_WARNINGS_FILTER>
type SetAssetDetailsStatus = SetStatus<
    AssetDetailsTypes.SET_WARNINGS_STATUS | 
    AssetDetailsTypes.SET_AXLE_LOAD_STATUS | 
    AssetDetailsTypes.SET_ASSET_INFO_STATUS | 
    AssetDetailsTypes.SET_SENSOR_INFO_STATUS | 
    AssetDetailsTypes.SET_VIBRATIONS_STATUS | 
    AssetDetailsTypes.SET_TIRE_PRESSURE_STATUS | 
    AssetDetailsTypes.SET_LINE_PRESSURE_STATUS | 
    AssetDetailsTypes.SET_TEMPERATURE_STATUS | 
    AssetDetailsTypes.SET_POWER_INPUTS_STATUS | 
    AssetDetailsTypes.SET_GPS_STATUS |
    AssetDetailsTypes.SET_STATUS_DIAGRAM_STATUS
>
type SetBool = SetBoolean<
    AssetDetailsTypes.SET_SHOW_ALL_WARNINGS | 
    AssetDetailsTypes.RESET_REDUCER
>

interface ISetAssetInfo { type: AssetDetailsTypes.SET_ASSET_INFO, payload: IAssetInfo | null }
interface ISetSensorInfo { type: AssetDetailsTypes.SET_SENSOR_INFO, payload: Array<IHardwareInfo> }
interface ISetStatusDiagram { type: AssetDetailsTypes.SET_STATUS_DIAGRAM, payload: any }
interface ISetWarnings { type: AssetDetailsTypes.SET_WARNINGS, payload: Array<IAssetWarning> }
interface ISetVibrations { type: AssetDetailsTypes.SET_VIBRATIONS, payload: IVIbrationData }
interface ISetTirePressure { type: AssetDetailsTypes.SET_TIRE_PRESSURE, payload: ITirePressureData }
interface ISetAxleLoad { type: AssetDetailsTypes.SET_AXLE_LOAD, payload: Array<IAxleLoadData> }
interface ISetAxleLoadCalibrationsCount { type: AssetDetailsTypes.SET_AXLE_LOAD_CALIBRATIONS_COUNT, payload: number | null }
interface ISetLinePressure { type: AssetDetailsTypes.SET_LINE_PRESSURE, payload: Array<ILinePressureData> }
interface ISetMotionAnnotations { type: AssetDetailsTypes.SET_MOTION_ANNOTATIONS, payload: IMotionAnnotations }
interface ISetTemperature { type: AssetDetailsTypes.SET_TEMPERATURE, payload: Array<ITemperatureData> }
interface ISetPowerInputs { type: AssetDetailsTypes.SET_POWER_INPUTS, payload: IPowerInputs | null }
interface ISetGps { type: AssetDetailsTypes.SET_GPS, payload: Array<IGpsData> }
interface ISetAssignedTpmsProfile { type: AssetDetailsTypes.SET_ASSIGNED_TPMS_PROFILE, payload: ITpmsProfile | null }
interface ISetChartsXRange { type: AssetDetailsTypes.SET_CHARTS_X_RANGE, payload: IChartXRange }


export type AssetDetailsActions = LoadingAssetDetails | SetAssetDetailsNumber | SetBool | ISetWarnings | SetWarningsFilter | 
    SetAssetDetailsStatus | ISetAxleLoad | ISetAssetInfo | ISetAxleLoadCalibrationsCount | ISetSensorInfo | ISetVibrations |
    ISetTirePressure | ISetLinePressure | ISetMotionAnnotations | ISetTemperature | ISetPowerInputs | ISetGps |
    ISetAssignedTpmsProfile | ISetStatusDiagram | ISetChartsXRange

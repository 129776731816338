import React from 'react'
import { convertDataEpochToDate } from '../../utils'
import classes from './styles.module.scss'
import moment from 'moment'
import { Button } from 'reactstrap'
import { NoDataLabels } from '../../enums'

const AssetTable = ({
  position,
  mac,
  rssi,
  speed,
  last_report_type,
  pedigree_firmware,
  hw_status_firmware,
  powered_by_internal_battery,
  smarthub_battery_voltage,
  pedigree_tpms_battery_voltage,
  gateway_external_battery_voltage,
  gateway_internal_battery_voltage,
  battery_reported_epoch,
  formatted_last_update,
  prev_update_at_epoch,
  locale,
  hardware_type,
  onAdminLogs,
  isSuperAdmin,
  id,
  lastUpdated,
}) => {
  const currentDate = +(+new Date() / 1000).toFixed(0)

  const onGetLogs = () => {
    const epochNow = moment().unix()
    let dateEpoch = lastUpdated ? lastUpdated : epochNow
    const date = convertDataEpochToDate(dateEpoch, null, null, true)
    onAdminLogs(id, date, 'sensors')
  }

  return (
    <tr className={classes.assetRow}>
      {hardware_type !== 'gateway' && (
        <td>
          {hardware_type === 'axle_load'
            ? 'Air Suspension'
            : hardware_type === 'line_pressure'
            ? 'Red Supply Line'
            : position
            ? locale[position] || position
            : NoDataLabels.DASH}
        </td>
      )}

      <td>{mac || NoDataLabels.DASH}</td>
      <td>
        {formatted_last_update !== NoDataLabels.DASH &&
        +(+new Date(formatted_last_update) / 1000).toFixed(0) <= currentDate
          ? formatted_last_update
          : prev_update_at_epoch
          ? convertDataEpochToDate(prev_update_at_epoch, null, null, true)
          : NoDataLabels.DASH}
      </td>

      {isSuperAdmin && (
        <>
          <td>{last_report_type || NoDataLabels.DASH}</td>
        </>
      )}

      {isSuperAdmin && hardware_type === 'gateway' && (
        <>
          <td>{rssi || NoDataLabels.DASH}</td>
          <td>
            {powered_by_internal_battery
              ? locale['No']
              : locale['Yes'] || NoDataLabels.DASH}
          </td>
          <td>
            {!battery_reported_epoch
              ? 'null'
              : gateway_external_battery_voltage || NoDataLabels.DASH}
          </td>
          <td>
            {!battery_reported_epoch
              ? 'null'
              : gateway_internal_battery_voltage || NoDataLabels.DASH}
          </td>
          <td>
            {!battery_reported_epoch
              ? 'null'
              : convertDataEpochToDate(
                  battery_reported_epoch,
                  null,
                  null,
                  true,
                ) || NoDataLabels.DASH}
          </td>
        </>
      )}

      {isSuperAdmin && hardware_type === 'hub_sensor' && (
        <>
          <td>{hw_status_firmware || NoDataLabels.DASH}</td>
          <td>
            {!battery_reported_epoch
              ? 'null'
              : smarthub_battery_voltage || NoDataLabels.DASH}
          </td>
          <td>
            {!battery_reported_epoch
              ? 'null'
              : convertDataEpochToDate(
                  battery_reported_epoch,
                  null,
                  null,
                  true,
                ) || NoDataLabels.DASH}
          </td>
        </>
      )}

      {isSuperAdmin && hardware_type === 'tire_sensor' && (
        <>
          <td>{speed || NoDataLabels.DASH}</td>
          <td>{pedigree_firmware || NoDataLabels.DASH}</td>
          <td>
            {!battery_reported_epoch
              ? 'null'
              : pedigree_tpms_battery_voltage || NoDataLabels.DASH}
          </td>
          <td>
            {!battery_reported_epoch
              ? 'null'
              : convertDataEpochToDate(
                  battery_reported_epoch,
                  null,
                  null,
                  true,
                ) || NoDataLabels.DASH}
          </td>
        </>
      )}

      {isSuperAdmin &&
        (hardware_type === 'axle_load' ||
          hardware_type === 'line_pressure') && (
          <td>{speed || NoDataLabels.DASH}</td>
        )}

      {isSuperAdmin && (
        <td>
          <Button outline color='secondary' size='sm' onClick={onGetLogs}>
            Logs
          </Button>
        </td>
      )}
    </tr>
  )
}

export default AssetTable

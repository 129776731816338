import React, { useState } from 'react'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from 'reactstrap'
import classnames from 'classnames'
import AssetTable from '../../modules/AssetTable'
import TpmsStatusTable from '../../modules/TpmsStatusTable'
import TpmsStatusFilterMenu from '../../modules/TpmsStatusTable/TpmsStatusFilterMenu'
import { useTpmsColumns } from '../RivataTpmsTable/useTpmsColumns'

import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import { saveGoogleAnalyticsEvent } from '../../utils/utils'
import './tables.scss'
import { UnitsOfMeasurementConfig } from '../../constants/constants'

const TablesWrapper = ({ width, whiteLabelUrl, locale, preferences }) => {
  const {
    tpmsStatusTractorAssets,
    tpmsStatusTrailerAssets,
    isSuperAdmin,
    customerDefaults,
    isSuperStandardUser,
    userName,
  } = useTypedSelector((state) => ({
    tpmsStatusTractorAssets: state.dash.tpmsStatusTractorAssets,
    tpmsStatusTrailerAssets: state.dash.tpmsStatusTrailerAssets,
    isSuperAdmin: state.auth.isSuperAdmin,
    customerDefaults: state.common.customerDefaults,
    isSuperStandardUser: state.auth.isSuperStandardUser,
    userName: state.auth.user.userName,
  }))
  const {
    setTpmsStatusTrailerAssetsSortOptions,
    setTpmsStatusTractorAssetsSortOptions,
    setTpmsStatusTractorAssetsLimit,
    setTpmsStatusTrailerAssetsLimit,
    setTpmsStatusTractorAssetsOffset,
    setTpmsStatusTrailerAssetsOffset,
  } = useActions()

  const [activeTab, setActiveTab] = useState('assets')

  const [
    tpmsColumnsToShow,
    tpmsColumnsCheckboxesProps,
    onSetDefaultTpmsColumns,
    setColumnsOrder,
  ] = useTpmsColumns(userName)
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
    <Col lg={width} className='tables-wrapper mt-4'>
      <Nav tabs>
        {(customerDefaults?.subscriptions?.TIRE_SENSOR || isSuperAdmin) && ( // show tabs selector only if second tab is also available to select(tpms subscription)
          <>
            <NavItem className='tab-item'>
              <NavLink
                className={
                  'assets_tab ' +
                  classnames({ 'active-table_tab': activeTab === 'assets' })
                }
                onClick={() => toggleTab('assets')}
              >
                Assets
              </NavLink>
            </NavItem>

            <NavItem className='tab-item'>
              <NavLink
                className={
                  'assets_tab ' +
                  classnames({ 'active-table_tab': activeTab === 'tpmsStatus' })
                }
                onClick={() => {
                  saveGoogleAnalyticsEvent('tpms_status_button_click')
                  toggleTab('tpmsStatus')
                }}
              >
                TPMS Status
              </NavLink>
            </NavItem>
          </>
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='assets'>
          {activeTab === 'assets' ? (
            <Row>
              <AssetTable
                isSuperAdmin={isSuperAdmin}
                whiteLabelUrl={whiteLabelUrl}
                locale={locale}
                preferences={preferences}
                defaults={customerDefaults}
                isSuperStandardUser={isSuperStandardUser}
              />
            </Row>
          ) : null}
        </TabPane>
        <TabPane tabId='tpmsStatus'>
          {activeTab === 'tpmsStatus' ? (
            <>
              <Row>
                <TpmsStatusFilterMenu
                  columnsCheckboxesProps={tpmsColumnsCheckboxesProps}
                  onSetDefaultColumns={onSetDefaultTpmsColumns}
                />
              </Row>
              <Row>
                <TpmsStatusTable
                  title={`Tractor Tire Status (${
                    preferences.unitsOfMeasurementConfig?.pressure ===
                    UnitsOfMeasurementConfig.pressure.psi
                      ? 'PSI'
                      : 'BAR'
                  })`}
                  tpmsStatusAssets={tpmsStatusTractorAssets}
                  defaults={customerDefaults}
                  whiteLabelUrl={whiteLabelUrl}
                  preferences={preferences}
                  locale={locale}
                  isShowingLimit={true}
                  setSortOptions={setTpmsStatusTractorAssetsSortOptions}
                  setLimit={setTpmsStatusTractorAssetsLimit}
                  setOffset={setTpmsStatusTractorAssetsOffset}
                  columnsToShow={tpmsColumnsToShow}
                  setColumnsOrder={setColumnsOrder}
                />
              </Row>
              <Row>
                <TpmsStatusTable
                  title={`Trailer Tire Status (${
                    preferences.unitsOfMeasurementConfig?.pressure ===
                    UnitsOfMeasurementConfig.pressure.psi
                      ? 'PSI'
                      : 'BAR'
                  })`}
                  tpmsStatusAssets={tpmsStatusTrailerAssets}
                  defaults={customerDefaults}
                  whiteLabelUrl={whiteLabelUrl}
                  preferences={preferences}
                  locale={locale}
                  isShowingLimit={true}
                  setSortOptions={setTpmsStatusTrailerAssetsSortOptions}
                  setLimit={setTpmsStatusTrailerAssetsLimit}
                  setOffset={setTpmsStatusTrailerAssetsOffset}
                  columnsToShow={tpmsColumnsToShow}
                  setColumnsOrder={setColumnsOrder}
                />
              </Row>
            </>
          ) : null}
        </TabPane>
      </TabContent>
    </Col>
  )
}

export default TablesWrapper
